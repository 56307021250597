import {Component, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {LoadingController, ModalController, NavParams, PopoverController, ToastController} from "@ionic/angular";
import {Ionic4DatepickerModalComponent} from "@logisticinfotech/ionic4-datepicker";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'app-ajouter-date-fermeture',
    templateUrl: './ajouter-date-fermeture.component.html',
    styleUrls: ['./ajouter-date-fermeture.component.scss'],
})
export class AjouterDateFermetureComponent implements OnInit {

    dateDebut: any = null;
    heureDebut: any = new Date();
    dateFin: any = null;
    heureFin: any = new Date();
    dateObj: any = null;

    constructor(
        private popoverController: PopoverController,
        public modalCtrl: ModalController,
        private loadingController: LoadingController,
        public toastController: ToastController,
        private userService: UserService,
        private navParams: NavParams
    ) {
        this.dateObj = navParams.get('fermeture');
        if(this.dateObj){
            this.dateDebut = this.dateObj.debut;
            this.heureDebut = this.dateObj.debut;
            this.dateFin = this.dateObj.fin;
            this.heureFin = this.dateObj.fin;
        }
    }

    ngOnInit() {
    }

    close() {
        this.popoverController.dismiss();
    }

    days = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    datePickerObj: any = {
        // inputDate: this.inOneWeek(), // default new Date()
        fromDate: new Date(), // default null
        //toDate: new Date('2018-12-28'), // default null
        //showTodayButton: false, // default true
        closeOnSelect: true, // default false
        //disableWeekDays: [4], // default []
        mondayFirst: true, // default false
        setLabel: 'S',  // default 'Set'
        todayLabel: "Aujourd'hui", // default 'Today'
        closeLabel: 'Annuler', // default 'Close'
        titleLabel: 'Sélectionnez une date', // default null
        monthsList: this.months,
        weeksList: ["D", "L", "M", "M", "J", "V", "S"],
        dateFormat: 'DD MMMM YYYY', // default DD MMM YYYY
        clearButton: false, // default true
        momentLocale: 'fr-FR', // Default 'en-US'
        yearInAscending: true, // Default false
        //btnCloseSetInReverse: true, // Default false
        /*btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: '' // Default ''
        },*/
        arrowNextPrev: {
            nextArrowSrc: 'assets/icones/arrow_forward.svg',
            prevArrowSrc: 'assets/icones/arrow_back.svg'
        }, // This object supports only SVG files.
        /*highlightedDates: [
            { date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff' },
            { date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff' }
        ],*/ // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    }

    async openDatePicker(debut: boolean) {
        let date = debut ? this.dateDebut : this.dateFin;
        const datePickerModal = await this.modalCtrl.create({
            component: Ionic4DatepickerModalComponent,
            componentProps: {
                'objConfig': this.datePickerObj,
                'selectedDate': date ? date : new Date()
            },
            cssClass: 'li-ionic4-datePicker'
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data && data.data.date && data.data.date != "Invalid date") {
                    let newDate = new Date();
                    let dateData = data.data.date.split(" ");
                    newDate.setDate(dateData[0]);
                    newDate.setMonth(this.months.findIndex(e => e.toLowerCase() == dateData[1].toLowerCase()));
                    newDate.setFullYear(dateData[2]);
                    debut ? (this.dateDebut = newDate) : (this.dateFin = newDate);
                }
            });
    }

    dateTransform(d: any) {
        return new DatePipe('fr-FR').transform(d, 'd MMMM YYYY');
    }

    async presentLoading(message) {
        const loading = await this.loadingController.create({
            message: message
        });
        await loading.present();
    }

    async toast(message: string, duration: number) {
        const toast = await this.toastController.create({
            message: message,
            duration: duration
        });
        toast.present();
    }

    inOneWeek() {
        let today = new Date();
        today.setDate(today.getDate() + 7);
        return today;
    }

    changeHeure($event, debut) {
        debut ? (this.heureDebut = new Date($event.detail.value)) : (this.heureFin = new Date($event.detail.value));
    }

    valider() {
        this.presentLoading("Ajout d'une date de fermeture...")
            .then(() => {
                this.dateDebut.setHours(this.heureDebut.getHours());
                this.dateDebut.setMinutes(this.heureDebut.getMinutes());
                this.dateFin.setHours(this.heureFin.getHours());
                this.dateFin.setMinutes(this.heureFin.getMinutes());
                if (this.userService.entityData.fermetures) {
                    this.userService.entityData.fermetures.push({
                        debut: this.dateDebut,
                        fin: this.dateFin
                    })
                } else {
                    this.userService.entityData['fermetures'] = [{
                        debut: this.dateDebut,
                        fin: this.dateFin
                    }]
                }
                this.userService.updateEntityData()
                    .then(() => this.popoverController.dismiss())
                    .then(() => this.loadingController.dismiss())
                    .then(() => this.toast("La date de fermeture a été ajoutée.", 2000))
            })
    }

    modifier() {
        this.presentLoading("Modification de la date de fermeture...")
            .then(() => {
                this.dateDebut.setHours(this.heureDebut.getHours());
                this.dateDebut.setMinutes(this.heureDebut.getMinutes());
                this.dateFin.setHours(this.heureFin.getHours());
                this.dateFin.setMinutes(this.heureFin.getMinutes());
                this.userService.entityData.fermetures = this.userService.entityData.fermetures.filter(e => e != this.dateObj); //FIXME
                this.userService.entityData.fermetures.push({
                    debut: this.dateDebut,
                    fin: this.dateFin
                })
                this.userService.updateEntityData()
                    .then(() => this.popoverController.dismiss())
                    .then(() => this.loadingController.dismiss())
                    .then(() => this.toast("La date de fermeture a été modifiée.", 2000))
            })
    }

}
