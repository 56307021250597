import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {AlertController, PopoverController, ToastController} from "@ionic/angular";
import {UserService} from "../../services/user.service";
import {RdvDetailsComponent} from "../rdv-details/rdv-details.component";
import {RdvService} from "../../services/rdv.service";
import {MembresService} from "../../services/membres.service";
import {ModelesService} from "../../services/modeles.service";
import {NavigationExtras, Router} from "@angular/router";
import * as firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import {AjouterDateFermetureComponent} from "../ajouter-date-fermeture/ajouter-date-fermeture.component";

@Component({
    selector: 'app-datagrid',
    templateUrl: './datagrid.component.html',
    styleUrls: ['./datagrid.component.scss'],
})
export class DatagridComponent implements OnInit {

    @Input() columns: any;
    @Input() data: any;
    @Input() type: any;

    @Output() adminDeleted  = new EventEmitter<string>();
    @Output() adminEdited  = new EventEmitter<string>();

    index: number = 0;
    tri: string = "";
    public user: any;

    db = firebase.default.firestore();

    constructor(
        public userService: UserService,
        public popoverController: PopoverController,
        public rdvService: RdvService,
        public membresService: MembresService,
        public modelesService: ModelesService,
        private alertController: AlertController,
        public toastCtrl: ToastController,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.user = this.userService.userData;
    }
    

    dateTransform(d: any) {
        return new DatePipe('fr-FR').transform(d, 'dd/MM/yy');
    }

    hourTransform(d: any) {
        return new DatePipe('fr-FR').transform(d, 'HH') + "h" + new DatePipe('fr-FR').transform(d, 'mm');
    }

    async openDetails(row) {
        // if (this.type == 'clients') {
            // this.openClient(row);
        // } else 
        if (this.type == 'concessions') {
            this.openConcession(row.id);
        } else if (this.type == 'marques') {
            this.openMarque(row.id);
        }
    }

    async openRdvDetails(row) {
        const popover = await this.popoverController.create({
            component: RdvDetailsComponent,
            componentProps: {
                rdv: row,
            },
            translucent: true,
            cssClass: 'bigPopover'
        });
        await popover.present();
    }

    isRdv() {
        return this.type == 'rdvTableauDeBord' || this.type == 'rdvAtelier' || this.type == 'rdvEssai';
    }

    formatCibles(cibles) {
        let res = cibles[0];
        for (let i = 1; i < cibles.length; i++) {
            res = res + " / " + cibles[i]
        }
        return res;
    }

    datePassee(date) {
        return date <= new Date();
    }

    async alertDeleteNotif(notif) {
        const alert = await this.alertController.create({
            header: "Supprimer la notification",
            message: "Êtes-vous sûr de vouloir supprimer la notification?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.deleteNotif(notif);
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteNotif(notif) {
        delete this.userService.notifs[notif.id];
        this.userService.entityData.notifications = this.userService.entityData.notifications.filter(e => e != notif.id);
        Promise.all([
            this.userService.updateEntityData(),
            this.db.collection('notifications').doc(notif.id).delete()
        ]).then(() => {
            this.toast("La notification a été supprimée.", 2000);
        });
    }

    async alertDeleteVehicule(vehicule) {
        const alert = await this.alertController.create({
            header: "Supprimer le véhicule",
            message: "Êtes-vous sûr de vouloir supprimer le véhicule?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.deleteVehicule(vehicule);
                    }
                }
            ]
        });

        await alert.present();
    }

    async alertDeleteModele(modele) {
        const alert = await this.alertController.create({
            header: "Supprimer le modèle",
            message: "Ce modèle est peut-être lié à des clients d'une concession ! Êtes-vous sûr de vouloir supprimer le modèle?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.deleteModele(modele);
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteVehicule(vehicule) {
        delete this.userService.vehicules[vehicule.id];
        this.userService.entityData.vehiculesOccasion = this.userService.entityData.vehiculesOccasion.filter(e => e != vehicule.id);
        Promise.all([
            this.userService.updateEntityData(),
            this.db.collection('vehicules').doc(vehicule.id).delete(),
            firebase.default.storage().ref().child('vehicules').child(vehicule.id + '.jpg').delete()
        ]).then(() => {
            this.toast("Le véhicule a été supprimé.", 2000);
        });
    }

    deleteModele(modele) {
        delete this.modelesService.modeles[modele.id];
        this.userService.entityData.modeles = this.userService.entityData.modeles.filter(e => e != modele.id);
        let promises = [
            this.userService.updateEntityData(),
            this.db.collection('modeles').doc(modele.id).delete()
        ];
        modele.img.forEach(photo => {
            promises.push(firebase.default.storage().refFromURL(photo).delete());
        })
        Promise.all(promises).then(() => {
            this.toast("Le modèle a été supprimé.", 2000);
        });
    }

    async toast(message: string, duration: number) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
    }

    editNotif(row) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                notification: JSON.stringify(row)
            }
        };
        this.router.navigate(['create-notification'], navigationExtras);
    }

    editVehicule(row) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                vehicule: JSON.stringify(row)
            }
        };
        this.router.navigate(['ajouter-vehicule-occasion'], navigationExtras);
    }

    editModele(row: any) {
        if (row && row.id) {
            this.router.navigateByUrl(`ajouter-modele?${row.id}`);
        }
    }

    getPermis(row) {
        let res = row.permis[0];
        for (let i = 1; i < row.permis.length; i++) {
            res = res + ', ' + row.permis[i];
        }
        return res;
    }

    // openClient(row) {
        // let navigationExtras: NavigationExtras = {
            // queryParams: {
                // client: JSON.stringify(row)
            // }
        // };
        // this.router.navigate(['ajouter-client'], navigationExtras);
    // }

    enStockChange(event, modele) {
        if (event.detail.checked != this.userService.entityData.modeles[modele.id]) {
            if (event.detail.checked) {
                this.userService.entityData.modeles[modele.id] = {
                    aLaLocation: false,
                    aLEssai: false
                };
            } else {
                delete this.userService.entityData.modeles[modele.id];
            }
            this.userService.updateEntityData();
        }
    }

    openConcession(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                id: id
            }
        };
        this.router.navigate(['concession'], navigationExtras);
    }

    openMarque(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                id: id
            }
        };
        this.router.navigate(['ajouter-marque'], navigationExtras);
    }

    openFactures(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                id: id
            }
        };
        this.router.navigate(['factures-credits'], navigationExtras);
    }

    getRowStyle(row) {
        switch (this.type) {
            case "concessions": {
                return {
                    "cursor": "pointer"
                }
            }
            case "marques": {
                return {
                    "cursor": "pointer"
                }
            }
            case "clients": {
                return {
                    "cursor": "pointer"
                }
            }
            case "messagesATraiter": {
                return {
                    "background": "#E8EFFA"
                }
            }
            case 'rdvTableauDeBord': {
                return {
                    "font-weight": this.isToday(row.dateRdv) ? 'bold' : 'none'
                }
            }
            case 'rdvAtelier': {
                return {
                    "font-weight": this.isToday(row.dateRdv) ? 'bold' : 'none'
                }
            }
            case 'rdvEssai': {
                return {
                    "font-weight": this.isToday(row.dateRdv) ? 'bold' : 'none'
                }
            }
            default: {
                break;
            }
        }
    }

    isToday(someDate) {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    getVilleConcession(row) {
        return row.adresse.address_components.find(e => e.types.some(t => t == "locality")).long_name;
    }

    changeALaLocation(modele) {
        this.userService.entityData.modeles[modele.id]['aLaLocation'] = !this.userService.entityData.modeles[modele.id]['aLaLocation'];
        this.userService.updateEntityData();
    }

    changeALEssai(modele) {
        this.userService.entityData.modeles[modele.id]['aLEssai'] = !this.userService.entityData.modeles[modele.id]['aLEssai'];
        this.userService.updateEntityData();
    }

    async alertTraiterDemande(row) {
        const alert = await this.alertController.create({
            header: "Traiter la demande",
            message: "Êtes-vous sûr de vouloir marquer la demande comme Traitée?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Valider',
                    handler: (blah) => {
                        this.traiterDemande(row);
                    }
                }
            ]
        });

        await alert.present();
    }

    traiterDemande(demande) {
        this.userService.entityData.messagerie.aTraiter = this.userService.entityData.messagerie.aTraiter.filter(m => m != demande);
        demande['traiteDate'] = new Date();
        demande['traitePar'] = this.userService.userInfos.uid;
        delete demande['showDetails'];
        this.userService.entityData.messagerie.traites.push(demande);
        this.userService.updateEntityData();
        this.userService.toast("La demande a été notée comme Traitée.", 2000)
    }

    getData() {
        return this.sort(this.data).slice(this.index, this.maxSlice());
    }

    decrementIndex() {
        this.index = Math.max(0, this.index - 10);
    }

    incrementIndex() {
        if (this.index + 10 == this.maxIndex()) this.index = this.maxIndex();
    }

    maxSlice() {
        return Math.min(this.data.length, this.index + 10)
    }

    maxIndex() {
        return Math.min(this.data.length - 1, this.index + 10)
    }

    sortedTypes = ['gammesNeuf', 'gammesOccasion', 'modelesGamme'];

    isSortedArray() {
        return this.sortedTypes.some(e => e == this.type);
    }

    sort(data: [any]) {
        switch (this.type) {
            case 'gammesNeuf':
                switch (this.tri) {
                    case 'Modèle':
                        data = data.sort((a: any, b: any) => {
                            return a.nom < b.nom ? -1 : 1;
                        });
                        break;
                    case 'Catégorie':
                        data = data.sort((a: any, b: any) => {
                            return a.categorie < b.categorie ? -1 : 1;
                        });
                        break;
                    case 'Puissance':
                        data = data.sort((a: any, b: any) => {
                            return +a.puissance - +b.puissance;
                        });
                        break;
                    case 'Actif':
                        data = data.sort((a: any, b: any) => {
                            return (this.userService.entityData.modeles[a.id] === this.userService.entityData.modeles[b.id]) ? 0 : this.userService.entityData.modeles[a.id] ? -1 : 1;
                        });
                        break;
                    case 'À la location':
                        data = data.sort((a: any, b: any) => {
                            return (this.userService.entityData.modeles[a.id] === this.userService.entityData.modeles[b.id]) ? 0 : this.userService.entityData.modeles[a.id] ? -1 : 1;
                        });
                        data = data.sort((a: any, b: any) => {
                            return (this.userService.entityData.modeles[a.id]?.aLaLocation === this.userService.entityData.modeles[b.id]?.aLaLocation) ? 0 : this.userService.entityData.modeles[a.id]?.aLaLocation ? -1 : 1;
                        });
                        break;
                    case 'À l’essai':
                        data = data.sort((a: any, b: any) => {
                            return (this.userService.entityData.modeles[a.id] === this.userService.entityData.modeles[b.id]) ? 0 : this.userService.entityData.modeles[a.id] ? -1 : 1;
                        });
                        data = data.sort((a: any, b: any) => {
                            return (this.userService.entityData.modeles[a.id]?.aLEssai === this.userService.entityData.modeles[b.id]?.aLEssai) ? 0 : this.userService.entityData.modeles[a.id]?.aLEssai ? -1 : 1;
                        });
                        break;
                    default:
                        this.tri = "Puissance";
                        break;
                }
                break;
            case 'gammesOccasion':
                switch (this.tri) {
                    case 'Marque':
                        data = data.sort((a: any, b: any) => {
                            return this.modelesService.getMarque(a.marque).nom > this.modelesService.getMarque(a.marque).nom ? -1 : 1;
                        });
                        break;
                    case 'Modèle':
                        data = data.sort((a: any, b: any) => {
                            return a.nom < b.nom ? -1 : 1;
                        });
                        break;
                    case 'Catégorie':
                        data = data.sort((a: any, b: any) => {
                            return a.categorie < b.categorie ? -1 : 1;
                        });
                        break;
                    case 'Puissance':
                        data = data.sort((a: any, b: any) => {
                            return +a.puissance - +b.puissance;
                        });
                        break;
                    case 'Prix':
                        data = data.sort((a: any, b: any) => {
                            return +a.prix - +b.prix;
                        });
                        break;
                    case 'Nb km':
                        data = data.sort((a: any, b: any) => {
                            return +a.nbkm - +b.nbkm;
                        });
                        break;
                    case 'Permis':
                        data = data.sort((a: any, b: any) => {
                            return a.typePermis < b.typePermis ? -1 : 1;
                        });
                        break;
                    default:
                        this.tri = "Puissance";
                        break;
                }
                break;
            case 'modelesGamme':
                switch (this.tri) {
                    case 'Nom du modèle':
                        data = data.sort((a: any, b: any) => {
                            return a.nom < b.nom ? -1 : 1;
                        });
                        break;
                    case 'Catégorie':
                        data = data.sort((a: any, b: any) => {
                            return a.categorie < b.categorie ? -1 : 1;
                        });
                        break;
                    case 'Puissance':
                        data = data.sort((a: any, b: any) => {
                            return +a.puissance - +b.puissance;
                        });
                        break;
                    case 'Prix':
                        data = data.sort((a: any, b: any) => {
                            return +a.prix - +b.prix;
                        });
                        break;
                    case 'Permis':
                        data = data.sort((a: any, b: any) => {
                            return a.typePermis < b.typePermis ? -1 : 1;
                        });
                        break;
                    default:
                        this.tri = "Puissance";
                        break;
                }
                break;
            default:
                break;
        }
        return data;
    }

    async alertDeleteFermeture(fermeture) {
        const alert = await this.alertController.create({
            header: "Supprimer la période de fermeture",
            message: "Êtes-vous sûr de vouloir supprimer la période de fermeture?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.deleteFermeture(fermeture);
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteFermeture(fermeture) {
        this.userService.entityData.fermetures = this.userService.entityData.fermetures.filter(e => e != fermeture);
        this.userService.updateEntityData().then(() => {
            this.toast("La période de fermeture a été supprimée.", 2000);
        });
    }

    async editDateFermeture(fermeture) {
        this.userService.blurContent = true;
        const popover = await this.popoverController.create({
            component: AjouterDateFermetureComponent,
            componentProps: {fermeture: fermeture},
            translucent: true,
            showBackdrop: false,
            cssClass: 'addFermeturePopover',
        });
        await popover.present();

        await popover.onWillDismiss()
            .then((result) => {
                this.userService.blurContent = false;
            });
    }

    dateFacture(d) {
        if (d instanceof Date) {
            return new DatePipe('fr-FR').transform(d, 'dd MMMM yyyy');
        } else {
            if (d.seconds) {
                return new DatePipe('fr-FR').transform(new Date(d.seconds * 1000), 'dd MMMM yyyy');
            } else {
                return new DatePipe('fr-FR').transform(new Date(d), 'dd MMMM yyyy');
            }
        }
    }

    openDoc(link) {
        window.open(link, "_blank");
    }

    async alertDeleteFacture(facture) {
        const alert = await this.alertController.create({
            header: "Supprimer la facture",
            message: "Êtes-vous sûr de vouloir supprimer la facture?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.deleteFacture(facture);
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteFacture(facture) {
        this.userService.getClient(facture.id).factures = this.userService.getClient(facture.id).factures.filter(e => e.numero != facture.numero);
        this.db.collection('clients').doc(facture.id).update({
            factures: this.userService.getClient(facture.id).factures
        }).then(() => {
                this.toast("Les modifications du client ont bien été enregistrées.", 2000);
        })
    }

    deleteAdmin(id: string) {
        this.adminDeleted .emit(id);
      }

      editAdmin(adminId: string) {
        this.adminEdited.emit(adminId);
    }
    
}
