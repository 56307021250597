import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr);

// Import des modules et services nécessaires
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ChangeDetectorRef} from "@angular/core";

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import * as firebase from "firebase/compat/app";
firebase.default.initializeApp(environment.firebase);
import {environment} from '../environments/environment';

import { Ionic4DatepickerModule } from
      '@logisticinfotech/ionic4-datepicker';

// Import des composants déclarés localement
import {ChooseRdvMembreComponent} from "./components/choose-rdv-membre/choose-rdv-membre.component";
import {RdvDetailsComponent} from "./components/rdv-details/rdv-details.component";
import {RdvAtelierPageModule} from "./pages/rdv-atelier/rdv-atelier.module";
import {CreateCibleComponent} from "./components/create-cible/create-cible.component";
import {CreateCategorieComponent} from "./components/create-categorie/create-categorie.component";
import {SharedModule} from "./shared/shared.module";
import {HttpClient} from "@angular/common/http";
import { HttpClientModule } from '@angular/common/http';
import { CodeInputModule } from 'angular-code-input';
import {DatePipe} from "@angular/common";
import {AjouterDateFermetureComponent} from "./components/ajouter-date-fermeture/ajouter-date-fermeture.component";
import {BientotPopoverComponent} from "./components/bientot-popover/bientot-popover.component";
import {AjouterFactureComponent} from "./components/ajouter-facture/ajouter-facture.component";
import { AjouterFichierComponent } from './components/ajouter-fichier/ajouter-fichier.component';

@NgModule({
  declarations: [AppComponent,
    ChooseRdvMembreComponent,
    RdvDetailsComponent,
    CreateCibleComponent,
    CreateCategorieComponent,
    AjouterDateFermetureComponent,
    BientotPopoverComponent,
    AjouterFactureComponent,
    AjouterFichierComponent,
  ],
  // Composants utilisés en dehors de la déclaration normale
  entryComponents: [], 
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    Ionic4DatepickerModule, RdvAtelierPageModule, SharedModule,
    FormsModule, ReactiveFormsModule, HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    CodeInputModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HttpClient,
    HttpClientModule,
    DatePipe,
    {provide: LOCALE_ID, useValue: "fr-FR"},
  ],
  // Composant principal à démarrer
  bootstrap: [AppComponent],
})
export class AppModule {}
