import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-ajouter-fichier',
  templateUrl: './ajouter-fichier.component.html',
  styleUrls: ['./ajouter-fichier.component.scss'],
})
export class AjouterFichierComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef; // file input element

  @Input() acceptedFileTypes: string; // for restricting file types from parent pages

  selectedFile: File | null = null; // selected file

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() { }

  // close modal
  closeAddFile() {
    this.modalCtrl.dismiss();
  }

  // pick file from device and emit it
  addFile(event: any) {
    if (event.target.files && event.target.files.length) {
      this.selectedFile = event.target.files[0];
    }
  }

  // button to confirm file
  async confirmFile() {
    if (this.selectedFile) {
      this.modalCtrl.dismiss(this.selectedFile);
    } else { }
  }

  // button to delete file
  deleteFile() {
    this.selectedFile = null;
  }
}
