import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MembresService} from "../../services/membres.service";
import {RdvService} from "../../services/rdv.service";
import {ModelesService} from "../../services/modeles.service";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'app-rdv-details',
    templateUrl: './rdv-details.component.html',
    styleUrls: ['./rdv-details.component.scss'],
})
export class RdvDetailsComponent implements OnInit, OnDestroy {

    @Input() rdv: any;

    hourDate = "";

    constructor(
        public membresService: MembresService,
        public rdvService: RdvService,
        public modelesService: ModelesService,
        public userService: UserService
    ) {
    }

    ngOnInit() {
        this.hourDate = this.rdv.dateRdv.getHours() + ":" + (this.rdv.dateRdv.getMinutes() < 10 ? '0' : '') + this.rdv.dateRdv.getMinutes();
    }

    ngOnDestroy(){
        this.rdvService.update(this.rdv.id);
    }

    changeHeure($event) {
        let newHour = $event.detail.value.split(":");
        this.rdv.dateRdv.setHours(newHour[0], newHour[1]);
    }

    changeMembre(rdv, ev){
        rdv.membre = ev.detail.value;
    }

}
