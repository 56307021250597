import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DatePickerComponent} from "../components/date-picker/date-picker.component";
import {DatagridComponent} from "../components/datagrid/datagrid.component";
import {PublicationComponent} from "../components/publication/publication.component";
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {IconeInfosUtilisateurComponent} from "../components/icone-infos-utilisateur/icone-infos-utilisateur.component";
import {HeaderComponent} from "../components/header/header.component";
import {TelInputComponent} from "../components/tel-input/tel-input.component";

@NgModule({
    declarations: [
        DatePickerComponent,
        DatagridComponent,
        PublicationComponent,
        IconeInfosUtilisateurComponent,
        HeaderComponent,
        TelInputComponent
    ],
    exports: [
        DatePickerComponent,
        DatagridComponent,
        PublicationComponent,
        IconeInfosUtilisateurComponent,
        HeaderComponent,
        TelInputComponent
    ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule, ReactiveFormsModule
  ]
})
export class SharedModule {}
