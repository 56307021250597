import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'tableau-de-bord',
    loadChildren: () => import('./pages/tableau-de-bord/tableau-de-bord.module').then( m => m.TableauDeBordPageModule)
  },
  {
    path: 'concession',
    loadChildren: () => import('./pages/concession/concession.module').then( m => m.ConcessionPageModule)
  },
  {
    path: 'rdv-atelier',
    loadChildren: () => import('./pages/rdv-atelier/rdv-atelier.module').then( m => m.RdvAtelierPageModule)
  },
  {
    path: 'rdv-essais',
    loadChildren: () => import('./pages/rdv-essais/rdv-essais.module').then( m => m.RdvEssaisPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'ajouter-client',
    loadChildren: () => import('./pages/ajouter-client/ajouter-client.module').then(m => m.AjouterClientPageModule)
  },
  {
    path: 'achat-credits',
    loadChildren: () => import('./pages/achat-credits/achat-credits.module').then( m => m.AchatCreditsPageModule)
  },
  {
    path: 'factures-credits',
    loadChildren: () => import('./pages/factures-credits/factures-credits.module').then( m => m.FacturesCreditsPageModule)
  },
  {
    path: 'ciblage',
    loadChildren: () => import('./pages/ciblage/ciblage.module').then( m => m.CiblagePageModule)
  },
  {
    path: 'gammes',
    loadChildren: () => import('./pages/gammes/gammes.module').then( m => m.GammesPageModule)
  },
  {
    path: 'ajouter-vehicule-occasion',
    loadChildren: () => import('./pages/ajouter-vehicule-occasion/ajouter-vehicule-occasion.module').then( m => m.AjouterVehiculeOccasionPageModule)
  },
  {
    path: 'publications',
    loadChildren: () => import('./pages/publications/publications.module').then( m => m.PublicationsPageModule)
  },
  {
    path: 'categories-publications',
    loadChildren: () => import('./pages/categories-publications/categories-publications.module').then( m => m.CategoriesPublicationsPageModule)
  },
  {
    path: 'ajouter-publication',
    loadChildren: () => import('./pages/ajouter-publication/ajouter-publication.module').then( m => m.AjouterPublicationPageModule)
  },
  {
    path: 'avantages',
    loadChildren: () => import('./pages/avantages/avantages.module').then( m => m.AvantagesPageModule)
  },
  {
    path: 'ajouter-avantage',
    loadChildren: () => import('./pages/ajouter-avantage/ajouter-avantage.module').then( m => m.AjouterAvantagePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'create-notification',
    loadChildren: () => import('./pages/ajouter-notification/create-notification.module').then(m => m.CreateNotificationPageModule)
  },
  {
    path: 'conseils',
    loadChildren: () => import('./pages/conseils/conseils.module').then( m => m.ConseilsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'ajouter-membre',
    loadChildren: () => import('./pages/ajouter-membre/ajouter-membre.module').then( m => m.AjouterMembrePageModule)
  },
  {
    path: 'ajouter-conseil',
    loadChildren: () => import('./pages/ajouter-conseil/ajouter-conseil.module').then( m => m.AjouterConseilPageModule)
  },
  {
    path: 'concessions',
    loadChildren: () => import('./pages/concessions/concessions.module').then( m => m.ConcessionsPageModule)
  },
  {
    path: 'ajouter-concession',
    loadChildren: () => import('./pages/ajouter-concession/ajouter-concession.module').then( m => m.AjouterConcessionPageModule)
  },
  {
    path: 'ajouter-marque',
    loadChildren: () => import('./pages/ajouter-marque/ajouter-marque.module').then( m => m.AjouterMarquePageModule)
  },
  {
    path: 'edit-marque',
    loadChildren: () => import('./pages/ajouter-marque/ajouter-marque.module').then( m => m.AjouterMarquePageModule)
  },
  {
    path: 'gestion',
    loadChildren: () => import('./pages/gestion/gestion.module').then( m => m.GestionPageModule)
  },
  {
    path: 'modeles',
    loadChildren: () => import('./pages/modeles/modeles.module').then( m => m.ModelesPageModule)
  },
  {
    path: 'ajouter-modele',
    loadChildren: () => import('./pages/ajouter-modele/ajouter-modele.module').then( m => m.AjouterModelePageModule)
  },
  {
    path: 'edit-modele',
    loadChildren: () => import('./pages/ajouter-modele/ajouter-modele.module').then( m => m.AjouterModelePageModule)
  },
  {
    path: 'administrateurs',
    loadChildren: () => import('./pages/administrateurs/administrateurs.module').then( m => m.AdministrateursPageModule)
  },
  {
    path: 'ajouter-administrateur',
    loadChildren: () => import('./pages/ajouter-administrateur/ajouter-administrateur.module').then( m => m.AjouterAdministrateurPageModule)
  },
  {
    path: 'mes-informations',
    loadChildren: () => import('./pages/mes-informations/mes-informations.module').then( m => m.MesInformationsPageModule)
  },
  {
    path: 'messagerie',
    loadChildren: () => import('./pages/messagerie/messagerie.module').then( m => m.MessageriePageModule)
  },
  {
    path: 'creer-un-compte',
    loadChildren: () => import('./pages/creer-un-compte/creer-un-compte.module').then( m => m.CreerUnComptePageModule)
  },
  {
    path: 'ajouter-facture',
    loadChildren: () => import('./pages/ajouter-facture/ajouter-facture.module').then( m => m.AjouterFacturePageModule)
  },
  {
    path: 'dates-fermeture',
    loadChildren: () => import('./pages/dates-fermeture/dates-fermeture.module').then( m => m.DatesFermeturePageModule)
  },
  {
    path: 'importation-donnees',
    loadChildren: () => import('./pages/importation-donnees/importation-donnees.module').then( m => m.ImportationDonneesPageModule)
  },
  {
    path: 'demandes-inscription-clients',
    loadChildren: () => import('./pages/demandes-inscription-clients/demandes-inscription-clients.module').then( m => m.DemandesInscriptionClientsPageModule)
  },
  {
    path: 'demandes-inscription-concession',
    loadChildren: () => import('./pages/demandes-inscription-concession/demandes-inscription-concession.module').then( m => m.DemandesInscriptionConcessionPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
