import {Injectable} from '@angular/core';
import * as firebase from "firebase/compat";

@Injectable({
    providedIn: 'root'
})
export class MembresService {

    membres = {};/*[
        {
            id: "Julien",
            prenom: "Julien",
            nom: "Nom1",
            poste: "Directeur de la concession",
            img: "assets/membres/Julien.jpeg",
        },
        {
            id: "Lucas",
            prenom: "Lucas",
            nom: "Nom2",
            poste: "Vendeur",
            img: "assets/membres/Lucas.jpeg",
        },
        {
            id: "Baptiste",
            prenom: "Baptiste",
            nom: "Nom3",
            poste: "Mécanicien",
            img: "assets/membres/Baptiste.jpeg"
        },
        {
            id: "Valentin",
            prenom: "Valentin",
            nom: "Nom4",
            poste: "Chef d'atelier",
            img: "assets/membres/Valentin.jpeg",
        },
        {
            id: "Nicolas",
            prenom: "Nicolas",
            nom: "Nom5",
            poste: "Responsable atelier",
            img: "assets/membres/Nicolas.jpeg",
        },
        {
            id: "Greg",
            prenom: "Greg",
            nom: "Nom6",
            poste: "Responsable concession",
            img: "assets/membres/Greg.jpeg",
        },

    ]*/

    constructor() {
    }

    getData() {
        /*let db = firebase.default.firestore();
        let self = this;
        db.collection('membres').onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(client => {
                self.clients[client.id] = client.data();
                self.clients[client.id].id = client.id;
            });
        });*/
    }

    getMember(id){
        return this.membres[id];
    }

    getMembers(){
        return Object.values(this.membres).filter(e => !e['suspended']).sort((a: any, b: any) => a.prenom.localeCompare(b.prenom)) as any[];
    }
}
