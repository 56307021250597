import {Injectable} from '@angular/core';
import {AlertController, LoadingController, PopoverController, ToastController} from "@ionic/angular";
import {Router} from "@angular/router";
import * as firebase from "firebase/compat/app";
import {MembresService} from "./membres.service";
import {RdvService} from "./rdv.service";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {ModelesService} from "./modeles.service";
import {ConseilsService} from "./conseils.service";
import {AdminService} from "./admin.service";
import {Concession, Horaires, Day, Heure} from "../shared/concession";
import {Marque} from "../shared/marque";
import {Modele} from "../shared/modele";
import {BehaviorSubject, Subject, of, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';


interface DemandeInscription {
  mail: string;
  nom: string;
  prenom: string;
  concession: string;
  [key: string]: any; // Pour permettre des propriétés supplémentaires
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    blurContent: boolean = false;

    increment = firebase.default.firestore.FieldValue.increment(1);

    private myObservable = new Subject<null>();
    entityUpdate = this.myObservable.asObservable();

    idEntity: string;

    userInfos: any;

    userData: any = null;
    //4Create $
    userData$ :BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataUpdatedSource = new Subject<void>();
    dataUpdated$ = this.dataUpdatedSource.asObservable();

    nbConnexionsClients$ = new BehaviorSubject<number>(0);

    // @ts-ignore
    entityData: any;

    publications = {};
    publicationsToValidate = {};
    vehicules = {};
    avantages = {};
    notifs = {};
    clients = {};
    factures = {};
    admins = {};

    

    db = firebase.default.firestore();

    connecting: boolean = false;
    errorPassword = '';

    typesPermis = ["A", "A1", "A2", "AM", "B", "BSR", "AUCUN"];

    nbUsersConcessio: number;
    nbConcessions: number;
    nbConnexions: number;
    nbConnexionsClients: number;
    mostTriedModel: string;
    demandesDEssai: number;
    mostConsultedModel: string = null;

    loadingControllerOpen = false;
    private nbDemandesATraiterSubject = new BehaviorSubject<number>(0);
    private nbDemandesInscriptionClientATraiterSubject = new BehaviorSubject<number>(0);

    constructor(
        public toastCtrl: ToastController,
        private router: Router,
        private membresService: MembresService,
        private rdvService: RdvService,
        public angularFireAuth: AngularFireAuth,
        private modelesService: ModelesService,
        private conseilsService: ConseilsService,
        private adminService: AdminService,
        private alertController: AlertController,
        public loadingController: LoadingController,
        private popoverController: PopoverController,
        private afs : AngularFirestore,
        private functions: AngularFireFunctions
    ) {
        this.userData$.subscribe(userData => {
            if (userData) {
                this.loadNbDemandesATraiter();
                this.loadNbDemandesInscriptionClienATraiter();
            }
        });
        this.getNbConnexions();
        this.getNbConnexionsClients();
    }

    async login(email: string, password: string) {
        this.presentLoading("Connexion en cours").then(() => {
            return this.angularFireAuth.signInWithEmailAndPassword(email, password)
                .then((result) => {
                    if (result.user.emailVerified != true) {
                        this.SendVerificationMail();
                        this.connecting = false;
                        window.alert("Vous n'avez pas encore validé votre adresse mail. Allez vérifier votre boîte mail!");
                        this.loadingController.dismiss();
                    } else {
                        this.loginActions(result.user);
                    }
                }).catch((error) => {
                    this.connecting = false;
                    this.errorPassword = "Adresse email ou mot de passe incorrect";
                    this.loadingController.dismiss();
                    console.clear();
                });
        });
    }

    // loginActions(userInfos: any) {
    //     this.userInfos = userInfos;
    //     // this.db.collection('membres').doc(userInfos.uid).set({
    //     //     dateAjout: new Date(),
    //     //     id: userInfos.uid,
    //     //     img: "https://firebasestorage.googleapis.com/v0/b/concessio-6e799.appspot.com/o/membres%2FuoYSlo6pj8aU0x4cAiIeZDubvzn2.jpg?alt=media&token=bc42bbad-0a10-496e-a4a5-23725ee9b187",
    //     //     nom: "Riollet",
    //     //     poste: "Directeur",
    //     //     prenom: "Antoine"
    //     // })
    //     this.db.collection('utilisateurs').doc(userInfos.uid).get().then(user => {
    //         this.userData = user.data();
    //         Promise.all([
    //             this.modelesService.getData(user.data().type),
    //             this.conseilsService.getData(),
    //             this.incrementDateConnections(),
    //             this.deleteOldDateConnections()
    //         ]).then(() => {
    //             switch (user.data().type) {
    //                 case 'admin': {
    //                     this.idEntity = 'adminData';
    //                     this.getAdminData().then(() => {
    //                         this.router.navigate(['tableau-de-bord']).then(() => {
    //                             this.connecting = false;
    //                             this.loadingController.dismiss();
    //                         })
    //                     })
    //                     break;
    //                 }
    //                 case 'concession': {
    //                     this.idEntity = user.data().concession;
    //                     this.getConcessionData().then(() => {
    //                             if (this.entityData['suspended']) {
    //                                 this.alertConcessionSuspended()
    //                                     .then(() => this.angularFireAuth.signOut())
    //                                     .then(() => {
    //                                         this.connecting = false;
    //                                         this.loadingController.dismiss();
    //                                     });
    //                             } else {
    //                                 this.router.navigate(['tableau-de-bord']).then(() => {
    //                                     this.connecting = false;
    //                                     this.loadingController.dismiss();
    //                                 })
    //                             }
    //                         }
    //                     )
    //                     break;
    //                 }
    //                 case 'marque': {
    //                     this.idEntity = user.data().marque;
    //                     console.log(user.data().marque)
    //                     this.getMarqueData()
    //                         .then(() => this.router.navigate(['tableau-de-bord']))
    //                         .then(() => {
    //                             this.connecting = false;
    //                             this.loadingController.dismiss();
    //                         })
    //                     break;
    //                 }
    //                 default:
    //                     break;
    //             }
    //             if (this.userData.changeMdp) {
    //                 this.presentAlertMdp();
    //             }
    //         })
    //     })
    // }


    async loginActions(userInfos: any) {
        this.userInfos = userInfos;
        this.loadingControllerOpen = true; 

        try {
            const userDoc = await this.db.collection('utilisateurs').doc(userInfos.uid).get();
            this.userData = userDoc.data();
            this.userData$.next(userDoc.data());

            // Appelez la méthode setUser pour stocker les informations de l'utilisateur
            this.setUser(this.userData);

            await Promise.all([
                this.modelesService.getData(userDoc.data().type),
                this.conseilsService.getData(),
                this.incrementDateConnections(),
                this.deleteOldDateConnections()
            ]);

            switch (userDoc.data().type) {
                case 'admin': {
                    this.idEntity = 'adminData';
                    await this.getAdminData();                    
                    await this.router.navigate(['tableau-de-bord']);
                    break;
                }
                case 'concession': {
                    this.idEntity = userDoc.data().concession;
                    await this.getConcessionData()
                    if (this.entityData['suspended']) {
                        await this.alertConcessionSuspended();
                        await this.angularFireAuth.signOut();
                    } else {
                        await this.router.navigate(['tableau-de-bord']);
                    }                    
                    break;
                }
                case 'marque': {
                    this.idEntity = userDoc.data().marque;
                    await this.getMarqueData();
                    await this.router.navigate(['tableau-de-bord']);
                    break;
                }
                default:
                    break;
            }

            

            if (this.userData.changeMdp) {
                await this.presentAlertMdp();
            }
            this.dataUpdatedSource.next();
        } catch (error) {
            console.error('Error in loginActions:', error);
            // Ajouter toute gestion d'erreur nécessaire ici
        } finally {
            if (this.loadingControllerOpen) {
                await this.loadingController.dismiss();
                this.loadingControllerOpen = false;
            }
            this.connecting = false;
        }
    }
    

    async alertConcessionSuspended() {
        const alert = await this.alertController.create({
            header: "Concession suspendue",
            message: "Votre concession a été suspendue par l'administrateur. Pour toute information, veuillez contacter Concessio.",
            buttons: [
                {
                    text: "Compris",
                    role: 'cancel',
                    cssClass: 'primary',
                    handler: (blah) => {
                    }
                },
            ]
        });
        await alert.present();
    }

    async incrementDateConnections() {
        let today = new Date();
        let formattedDate = today.toISOString().split('T')[0].replace(/-/g, ''); // Format "YYYYMMDD"
    
        return this.db.collection('admin').doc('connexions').update({
            [formattedDate]: this.increment
        })
    }
    

    async deleteOldDateConnections() {
        let today = new Date();
        let oneMonthAgo = new Date();
        oneMonthAgo.setMonth(today.getMonth() - 1);
        let formattedOneMonthAgo = parseInt(`${oneMonthAgo.getFullYear()}${(oneMonthAgo.getMonth() + 1).toString().padStart(2, '0')}${oneMonthAgo.getDate().toString().padStart(2, '0')}`, 10);
        let connexionsDoc = await this.db.collection('admin').doc('connexions').get();
        if (connexionsDoc.exists) {
            let updates = {};
            Object.keys(connexionsDoc.data()).forEach(key => {
                let formattedKey = parseInt(key, 10);
                if (formattedKey < formattedOneMonthAgo) {
                    updates[key] = firebase.default.firestore.FieldValue.delete();
                }
            });
            return this.db.collection('admin').doc('connexions').update(updates);
        }
    }
    

    SendVerificationMail() {
        return this.angularFireAuth.currentUser.then(user => {
            user.sendEmailVerification().then(() => {
                this.router.navigate(['connexion']).then(() => {
                    this.alertVerifEmail(user.email);
                })
            });
        });
    }

    async alertVerifEmail(email: string) {
        const alert = await this.alertController.create({
            header: "Email envoyé",
            message: "Un email de vérification a été envoyé à " + email,
            buttons: [
                {
                    text: "Compris",
                    role: 'cancel',
                    cssClass: 'primary',
                    handler: (blah) => {
                    }
                },
            ]
        });
        await alert.present();
    }

    async getConcessionData() {
        await this.db.collection('concessions').doc(this.idEntity).get().then(concession => {
            this.entityData = concession.data() as Concession;
        });
        await this.db.collection('concessions').doc(this.idEntity).onSnapshot(concession => {
            this.entityData = concession.data() as Concession;
            Object.values(this.entityData.horairesAtelier).forEach(a => {
                // @ts-ignore
                Object.values(a).forEach(b => {
                    if (b.end) b.end = new Date(b.end.seconds * 1000);
                    if (b.start) b.start = new Date(b.start.seconds * 1000);
                })
            })
            Object.values(this.entityData.horairesConcession).forEach(a => {
                // @ts-ignore
                Object.values(a).forEach(b => {
                    if (b.end) b.end = new Date(b.end.seconds * 1000);
                    if (b.start) b.start = new Date(b.start.seconds * 1000);
                })
            });
            if (this.entityData.fermetures) this.entityData.fermetures.forEach(f => {
                f.debut = new Date(f.debut.seconds * 1000);
                f.fin = new Date(f.fin.seconds * 1000);
            })
            if (this.entityData.messagerie) {
                this.entityData.messagerie.aTraiter.forEach(m => {
                    m.date = new Date(m.date.seconds * 1000);
                })
                this.entityData.messagerie.traites.forEach(m => {
                    m.date = new Date(m.date.seconds * 1000);
                    m.traiteDate = new Date(m.traiteDate.seconds * 1000);
                })
            } else {
                this.entityData['messagerie'] = {
                    aTraiter: [],
                    traites: []
                }
                this.updateEntityData();
            }
            const currentConcessions = this.adminService.concessions.getValue();
            currentConcessions[concession.id] = this.entityData;
            this.adminService.concessions.next(currentConcessions);
            // this.adminService.concessions[concession.id] = this.entityData;
            this.idEntity = concession.id;
            this.myObservable.next();
            return Promise.all([
                this.fbClientsData(),
                this.fbRdvData(),
                this.fbMembresData(),
                this.fbPublicationsData(),
                this.fbVehiculesData(),
                this.fbAvantagesData(),
                this.fbNotifsData()
            ])
        });
        
    }

    async getAdminData() {
        return this.db.collection('admin').doc('adminData').onSnapshot(res => {
            this.entityData = res.data() as Concession;
            if (this.entityData.messagerie) {
                this.entityData.messagerie.aTraiter.forEach(m => {
                    m.date = new Date(m.date.seconds * 1000);
                })
                this.entityData.messagerie.traites.forEach(m => {
                    m.date = new Date(m.date.seconds * 1000);
                    m.traiteDate = new Date(m.traiteDate.seconds * 1000);
                })
            } else {
                this.entityData['messagerie'] = {
                    aTraiter: [],
                    traites: []
                }
                this.updateEntityData();
            }
            this.myObservable.next();
            let adminPromises = [
                this.fbPublicationsData(),
                this.fbNotifsData(),
                this.db.collection('clients').get().then(snap => this.nbUsersConcessio = snap.size),
                this.db.collection('concessions').get().then(snap => this.nbConcessions = snap.size),
                this.getNbConnexions(),
                this.getNbConnexionsClients(),
                this.fbConcessionsData(),
                this.fbAvantagesData()
            ];
            if (this.isSuperAdmin()) {
                adminPromises.push(this.fbAdminsData());
            }
            // @ts-ignore
            return Promise.all(adminPromises).then(res=>{
            this.dataUpdatedSource.next();
            })
        });
    }

    async getMarqueData() {
        return this.db.collection('marques').doc(this.idEntity).onSnapshot(marque => {
            this.entityData = marque.data() as Marque;
            this.modelesService.marques[this.idEntity] = this.entityData;
            let limitDate = new Date();
            limitDate.setDate(limitDate.getDate() - 30);
            let limiteTS = firebase.default.firestore.Timestamp.fromDate(limitDate);
            let ids = JSON.parse(JSON.stringify(this.entityData.modeles));
            let collectionPath = this.db.collection('rdv');
            const batches = [];
            batches.push(this.fbModelesData(),
                this.db.collection('clients').where("marque", "==", this.idEntity).get().then(snap => this.nbUsersConcessio = snap.size),
                this.db.collection('concessions').where("marques", "array-contains", this.idEntity).get().then(snap => this.nbConcessions = snap.size));
            if (ids.length == 0) this.demandesDEssai = 0;
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("type", "==", "essai").where("id", "in", [...batch]).where("dateRdv", ">=", limiteTS)
                    .get().then(snap => {
                        this.demandesDEssai = snap.size;
                        let res = {};
                        snap.forEach(rdv => {
                            res[rdv.data().model] ? res[rdv.data().model]++ : res[rdv.data().model] = 1;
                        })
                        if (snap.size > 0) this.mostTriedModel = Object.keys(res).reduce((a, b) => res[a] > res[b] ? a : b);
                    }))
            }
            ids = JSON.parse(JSON.stringify(this.entityData.publications));
            collectionPath = this.db.collection('publications');
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch])
                    .get().then(querySnapshot => {
                        querySnapshot.forEach(pub => {
                            this.publications[pub.id] = pub.data();
                            this.publications[pub.id].date = this.publications[pub.id].date.toDate();
                        });
                    }))
            }
            this.dataUpdatedSource.next();
            return Promise.all(batches).then(res=>{                
                this.modelesService.dataUpdatedSource.next();
                this.dataUpdatedSource.next();
            })
            
        });
    }

    async fbConcessionsData() {
        if (this.adminService.getConcessions().length == 0) {
            let self = this;
            return this.db.collection('concessions').onSnapshot(function (querySnapshot) {
                // Retrieve the current state of concessions
                let currentConcessions = self.adminService.concessions.getValue();
                
                querySnapshot.forEach(concession => {
                    const concessionData = concession.data();
                    concessionData.id = concession.id;
    
                    if (concessionData.dateDerniereFacture) {
                        concessionData.dateDerniereFacture = concessionData.dateDerniereFacture.toDate();
                    }
    
                    concessionData.dateCreation = concessionData.dateCreation.toDate();
    
                    if (concessionData.fermetures) concessionData.fermetures.forEach(f => {
                        f.debut = new Date(f.debut.seconds * 1000);
                        f.fin = new Date(f.fin.seconds * 1000);
                    });
    
                    if (concessionData.horairesAtelier) {
                        Object.values(concessionData.horairesAtelier).forEach((a : any) => {
                            if (a.open) Object.values(a).forEach((b :  any) => {
                                if (b.open) {
                                    b.end = new Date(b.end.seconds * 1000);
                                    b.start = new Date(b.start.seconds * 1000);
                                }
                            });
                        });
                    }
    
                    if (concessionData.horairesConcession) {
                        Object.values(concessionData.horairesConcession).forEach((a : any) => {
                            if (a.open) Object.values(a).forEach((b : any) => {
                                if (b.open) {
                                    b.end = new Date(b.end.seconds * 1000);
                                    b.start = new Date(b.start.seconds * 1000);
                                }
                            });
                        });
                    }
    
                    // Update the concession in the current state
                    currentConcessions[concession.id] = concessionData;
                });
    
                // Push the updated concessions back into the BehaviorSubject
                self.adminService.concessions.next(currentConcessions);
            });
        }
    }
    

    async fbRdvData() {
        if (this.rdvService.getRdvList().length == 0) {
            let self = this;
            return this.db.collection('rdv').where("concession", "==", this.idEntity).onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(rdv => {
                    self.rdvService.data[rdv.id] = rdv.data();
                    self.rdvService.data[rdv.id].dateEnvoi = self.rdvService.data[rdv.id].dateEnvoi.toDate();
                    self.rdvService.data[rdv.id].dateRdv = self.rdvService.data[rdv.id].dateRdv.toDate();
                    self.rdvService.data[rdv.id].id = rdv.id;
                    self.rdvService.data[rdv.id].showDetails = false;
                });
                self.rdvService.dataUpdatedSource.next();
            });
        }
    }

    async fbAdminsData() {
        let self = this;
        let ids = JSON.parse(JSON.stringify(this.entityData.admins));
        let collectionPath = this.db.collection('utilisateurs');
        const batches = [];
        while (ids.length) {
            const batch = ids.splice(0, 10);
            batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(admin => {
                    self.admins[admin.id] = admin.data();
                });
            }))
        }
        return Promise.all(batches);
    }

    async fbMembresData() {
        if (this.membresService.getMembers().length == 0 && this.entityData.membres.length > 0) {
            let self = this;
            let ids = JSON.parse(JSON.stringify(this.entityData.membres));
            let collectionPath = this.db.collection('membres');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(membre => {
                        self.membresService.membres[membre.id] = membre.data();
                    });
                }))
            }
            return Promise.all(batches);
        }
    }


    async fbPublicationsData() {
        let self = this;
    
        // Initialiser ou vider les publications à valider
        this.publicationsToValidate = {};
    if (this.userData.type == "concession") {
            // Écoute en temps réel de toutes les publications
            return this.db.collection('publications').onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(pub => {
                    // Vérifier si la publication est de la marque et si la marque est associée à la concession
                    if ((pub.data().author.type === "marque" && self.entityData.marques.includes(pub.data().author.id)) || pub.data().author.type === "admin") {
                        if (!pub.data().validatedConcessions || !pub.data().validatedConcessions.includes(self.idEntity)) {
                            if (!pub.data().unvalidatedConcessions || !pub.data().unvalidatedConcessions.includes(self.idEntity)) {
                                // Ajouter à publications à valider si pas déjà refusée
                                self.publicationsToValidate[pub.id] = pub.data();
                                self.publicationsToValidate[pub.id].date = self.publicationsToValidate[pub.id].date.toDate();
                            }
                        } else {
                            // Ajouter à publications validées si nécessaire
                            self.publications[pub.id] = pub.data();
                            self.publications[pub.id].date = self.publications[pub.id].date.toDate();
                        }
                    }

                    // Traiter également les publications liées directement à la concession
                    if (self.entityData.publications && self.entityData.publications.includes(pub.id)) {
                        self.publications[pub.id] = pub.data();
                        self.publications[pub.id].date = self.publications[pub.id].date.toDate();
                    }
                });
                self.dataUpdatedSource.next();
            });
        }
        else {
            // Traitement pour les autres types d'utilisateurs
            let ids = JSON.parse(JSON.stringify(this.entityData.publications));
            let collectionPath = this.db.collection('publications');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch])
                    .get().then(querySnapshot => {
                        querySnapshot.forEach(pub => {
                            self.publications[pub.id] = pub.data();
                            self.publications[pub.id].date = self.publications[pub.id].date.toDate();
                        });
                    }));
            }
            return Promise.all(batches).then(res=>{
                self.dataUpdatedSource.next();
            })
        }
    }
    



    async fbVehiculesData() {
        if (this.getVehicules().length == 0 && this.entityData.vehiculesOccasion.length > 0) {
            let self = this;
            let ids = JSON.parse(JSON.stringify(this.entityData.vehiculesOccasion));
            let collectionPath = this.db.collection('vehicules');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(vehicule => {
                        self.vehicules[vehicule.id] = vehicule.data();
                        self.vehicules[vehicule.id].dateCirc = self.vehicules[vehicule.id].dateCirc.toDate();
                        self.vehicules[vehicule.id].dateMiseEnVente = self.vehicules[vehicule.id].dateMiseEnVente.toDate();
                    });
                }))

            }
            return Promise.all(batches).then(res=>self.dataUpdatedSource.next())
        }
    }

    async fbAvantagesData() {
        if (this.getAvantages().length == 0 && this.entityData.avantages.length > 0) {
            let self = this;
            let ids = JSON.parse(JSON.stringify(this.entityData.avantages));
            let collectionPath = this.db.collection('avantages');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(avantage => {
                        // self.db.collection('avantages').doc(avantage.id).update({
                        //     author: {
                        //         id: self.userInfos.uid,
                        //         type: self.userData.type
                        //     }
                        // })
                        self.avantages[avantage.id] = avantage.data();
                        self.avantages[avantage.id].dateDebut = self.avantages[avantage.id].dateDebut.toDate();
                        self.avantages[avantage.id].dateFin = self.avantages[avantage.id].dateFin.toDate();
                    });
                    self.dataUpdatedSource.next();
                }));
            }
            return Promise.all(batches);
        }
    }

    async fbNotifsData() {
        if (this.getNotifs().length == 0 && this.entityData.notifications.length > 0) {
            let self = this;
            let ids = JSON.parse(JSON.stringify(this.entityData.notifications));
            let collectionPath = this.db.collection('notifications');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(notification => {
                        self.notifs[notification.id] = notification.data();
                        self.notifs[notification.id].datePrevue = self.notifs[notification.id].datePrevue.toDate();
                    });
                }));
                self.dataUpdatedSource.next();
            }
            return Promise.all(batches);
        }
    }

    async fbModelesData() {
        this.modelesService.marques[this.idEntity] = this.entityData;
        if (this.modelesService.getModeles().length == 0 && this.entityData.modeles.length > 0) {
            let self = this;
            let ids = JSON.parse(JSON.stringify(this.entityData.modeles));
            let collectionPath = this.db.collection('modeles');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(modele => {
                        self.modelesService.modeles[modele.id] = modele.data() as Modele;
                        self.modelesService.modeles[modele.id].id = modele.id;
                        self.modelesService.modeles[modele.id].dateSortie = self.modelesService.modeles[modele.id].dateSortie.toDate();
                        if (self.modelesService.modeles[modele.id].consultations) {
                            self.modelesService.modeles[modele.id].consultations.forEach(consultation => {
                                consultation.date = new Date(consultation.date.seconds * 1000);
                            })
                        }
                    });
                    Object.keys(self.modelesService.modeles).forEach(id => {
                        if (!self.mostConsultedModel || (self.modelesService.getModeleNbConsults(id) > self.modelesService.getModeleNbConsults(self.mostConsultedModel))) self.mostConsultedModel = id;
                    })
                }));
            }
            return Promise.all(batches);
        }
    }

    async fbClientsData() {
        if (this.getClients().length == 0 && this.entityData.clients.length > 0) {
            let self = this;
            let ids = JSON.parse(JSON.stringify(this.entityData.clients));
            let collectionPath = this.db.collection('clients');
            const batches = [];
            while (ids.length) {
                const batch = ids.splice(0, 10);
                batches.push(collectionPath.where("id", "in", [...batch]).onSnapshot(function (querySnapshot) {
                    querySnapshot.forEach(client => {
                        if(client){
                            self.clients[client.id] = client.data();
                            self.clients[client.id].dateNaissance = self.clients[client.id].dateNaissance.toDate();
                            self.clients[client.id].datePermis = self.clients[client.id].datePermis.toDate();
                            self.clients[client.id].dateRenouvellement = self.clients[client.id].dateRenouvellement.toDate();
                            self.clients[client.id].dateDerniereRevision = self.clients[client.id].dateDerniereRevision.toDate();
                            self.clients[client.id].dateMiseCirculation = self.clients[client.id].dateMiseCirculation.toDate();
                        }
                        
                    });
                    self.dataUpdatedSource.next();
                }));
            }
            return Promise.all(batches);
        }
    }

    async toast(message: string, duration: number) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
    }

    async updateEntityData() {
        return this.db.collection(this.userData.type == 'admin' ? 'admin' : this.userData.type + 's').doc(this.idEntity).update(this.entityData);
    }

    getPublications() {
            // Récupérer le tableau des concessions
    const concessions = this.adminService.getConcessions();
        // Créer un tableau pour stocker les marques
        let marques = [];

    // Parcourir chaque concession pour récupérer la marque
    concessions.forEach(concession => {
        // Vérifier si la propriété marque existe
        if (concession.marques) {
            // Ajouter la marque au tableau
            marques.push(concession.marques);
        }
    });
        return Object.values(this.publications).sort((a: any, b: any) => {

            return new Date(b.date).getTime() - new Date(a.date).getTime()
        }) as any[];
    }

    getPublicationsToValidate() {
        return Object.values(this.publicationsToValidate).sort((a: any, b: any) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
        }) as any[];
    }

    getCategories() {
        return this.entityData.categories ? this.entityData.categories : [];
    }

    sortByDate(arr) {
        return arr.sort((a: any, b: any) => {
            return a.date.getTime() - b.date.getTime();
        });
    }

    getVehicules() {
        return Object.values(this.vehicules) as any[];
    }

    getVehicule(id) {
        return this.vehicules[id];
    }

    getAvantages() {
        return Object.values(this.avantages) as any[];
    }

    getAvantage(id) {
        return this.avantages[id];
    }

    sortAvantagesByDate(arr) {
        let res = arr.sort((a: any, b: any) => {
            return b.dateFin.getTime() - a.dateFin.getTime();
        });
        return res.sort((a: any, b: any) => {
            return b.dateDebut.getTime() - a.dateDebut.getTime();
        });
    }

    getCibles() {
        return this.entityData?.cibles ? this.entityData.cibles.sort() : [];
    }

    getNotifs() {
        return Object.values(this.notifs).sort((a: any, b: any) => {
            return a.datePrevue.getTime() - b.datePrevue.getTime();
        }) as any[];
    }

    getNotif(id) {
        return this.notifs[id];
    }

    getClients(data = '') {
    let number = 0;
        let clients = Object.values(this.clients).sort((a: any, b: any) => {
            number = a.prenom - b.prenom;
            return a.prenom - b.prenom;
        }) as any[];
        return  clients
    }

    getClient(id) {
        return this.clients[id];
    }

    getAdmins() {
        return Object.values(this.admins) as any[];
    }

    datePassee(date) {
        return date <= new Date();
    }

    enStock(id) {
        return this.entityData.modeles[id];
    }

    sortByName(arr) {
        return arr.sort((a: any, b: any) => {
            return a.nom - b.nom;
        });
    }

    async presentLoading(message) {
        const loading = await this.loadingController.create({
            message: message
        });
        await loading.present();
    }

    signOut() {
        return this.presentLoading("Déconnexion en cours...")
            .then(() => this.angularFireAuth.signOut())
            .then(() => this.router.navigate(['login']))
            .then(() => {
                this.idEntity = null;
                this.userInfos = null;
                this.userData = null;
                this.userData$.next(null);
                this.entityData = null;
                this.publications = {};
                this.vehicules = {};
                this.avantages = {};
                this.notifs = {};
                this.clients = {};
                this.nbUsersConcessio = 0;
                this.nbConcessions = 0;
                this.nbConnexions = 0;
                this.nbConnexionsClients = 0;
                this.dataUpdatedSource.next();
            })
            .then(() => window.location.reload())
            .then(() => this.loadingController.dismiss())
    }

    async getNbConnexions() {
        let today = new Date();
        let formattedDate = today.toISOString().split('T')[0].replace(/-/g, '');
    
        this.db.collection('admin').doc('connexions').onSnapshot(res => {
            this.nbConnexions = res.data()[formattedDate];
        })
        return true;
    }

    async getNbConnexionsClients() {
        if (!this.userData) {
            return;
        }
    
        const concessionId = this.userData.concession;
    
        try {
            const concessionDoc = await this.db.collection('concessions').doc(concessionId).get();
    
            if (!concessionDoc.exists) {
                console.error("Concession document does not exist.");
                return;
            }
    
            const concessionData = concessionDoc.data();
    
            if (!concessionData.userConnections || !Array.isArray(concessionData.userConnections)) {
                console.error("userConnections is undefined or not an array.");
                return;
            }
    
            const totalConnexions = concessionData.userConnections.reduce((total, connection) => {
                return total + (connection.nbConnexions || 0); // Utilisez || 0 pour gérer les cas où nbConnexions pourrait être undefined
            }, 0);
    
            this.nbConnexionsClients$.next(totalConnexions);
            return totalConnexions;
        } catch (error) {
            console.error("Error fetching concession data:", error);
        }
    }
    
    
    

    fbFacturesData(idConcession: string) {
        let self = this;
        this.factures[idConcession] = [];
        this.db.collection('factures').where("concession", "==", idConcession).onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(facture => {
                self.factures[idConcession][facture.id] = facture.data();
                self.factures[idConcession][facture.id].date = self.factures[idConcession][facture.id].date.toDate();
            });
        })
    }

    isSuperAdmin(): boolean {
        return this.userData && this.userInfos && (this.userInfos.uid == 'uoYSlo6pj8aU0x4cAiIeZDubvzn2' || this.userInfos.uid == 'bZSwFvnrDlaf1QWfqd6DdV6l4Kz2'  || this.userInfos.uid == 'ux7kxQT9lFdV17i0gvMpWwQmAj53');
    }

    isAdmin() {
        if (this.userData && this.userData.type == 'admin') {
            return {
                id: this.userData.id,
                nom: this.userData.nom,
                prenom: this.userData.prenom
            };
        }
        return null;
    }


    async presentAlertMdp() {
        let alert = await this.alertController.create({
            header: "Modifiez votre mot de passe",
            message: "Votre compte a été créé avec un mot de passe fournit par Concessio. Modifiez votre mot de passe pour que les autres utilisateurs ne puissent pas accéder à votre compte.",
            buttons: [
                {
                    text: 'Modifier mon mot de passe',
                    handler: (blah) => {
                        this.router.navigate(['mes-informations']);
                    }
                }
            ]
        });
        await alert.present();
    }

    async deleteAdmin(adminId: string) {
        // Suppression locale
        if (this.admins[adminId]) {
            delete this.admins[adminId];
        }
    
        // Suppression dans Firestore
        const adminRef = this.db.collection('admin').doc('adminData');
        const utilisateursRef = this.db.collection('utilisateurs').doc(adminId);
    
        try {
                    // Récupérer le tableau 'admins' actuel
        const doc = await adminRef.get();
        if (!doc.exists) {
            throw new Error('Document does not exist!');
        }
        const admins = doc.data().admins || [];

        // Supprimer l'adminId du tableau
        const index = admins.indexOf(adminId);
        if (index !== -1) {
            admins.splice(index, 1);
        }

        // Remettre le tableau à jour
        await adminRef.update({ admins });

            await utilisateursRef.delete();
        } catch (error) {
            console.error("Error deleting admin: ", error);
        }
    }
    
    fetchRdvsEssais() {

        if (this.userData && this.userData.marque) {
            const marqueId = this.userData.marque;
            return this.afs.collection('rdv', ref => ref.where('marque', '==', marqueId).where('type', '==', 'essai'))
                .snapshotChanges()
                .pipe(
                    map(changes => changes.length) // Mapping to the count of documents
                );
        } else {
            return of(0); // Returning an Observable of 0 if conditions are not met
        }


    // return new Promise((resolve, reject) => {
    //     if (this.userData && this.userData.marque) {
    //         const marqueId = this.userData.marque;
    //         this.db.collection('rdv')
    //             .where('marque', '==', marqueId)
    //             .where('type', '==', 'essai')
    //             // .where('statut', '==', 'valider')
    //             .get()
    //             .then(querySnapshot => {
    //                 resolve(querySnapshot.size);
    //             })
    //             .catch(error => {
    //                 console.error("Error getting documents: ", error);
    //                 reject(error);
    //             });
    //     }
    //     else {
    //         resolve({});
    //     }
    // });
}

// Demande d'inscription d'un client d'une concession par encore inscrite à Concessio
getDemandesInscriptionConcessionTraiter(): Observable<any[]> {
    return this.afs.collection('demandes_inscriptions_clients', ref => 
      ref.where('statut', '==', 'traité')
         .orderBy('dateHeure', 'desc')
    ).valueChanges({ idField: 'id' });
}


  getDemandesInscriptionConcessionATraiter(): Observable<any[]> {
    return this.afs.collection('demandes_inscriptions_clients', ref => 
      ref.where('statut', '==', 'à traiter')
         .orderBy('dateHeure', 'desc')
    ).valueChanges({ idField: 'id' });
}

    
updateDemandeInscriptionConcessionStatut(id: string, statut: string): Promise<void> {
    return this.afs.collection('demandes_inscriptions_clients').doc(id).update({
      statut: statut
    });
  }
  deleteDemandeInscriptionConcession(id: string): Promise<void> {
    return this.afs.collection('demandes_inscriptions_clients').doc(id).delete();
  }

  
  private loadNbDemandesATraiter() {
    this.afs.collection('demandes_inscriptions_clients', ref => 
      ref.where('statut', '==', 'à traiter')
    ).snapshotChanges().pipe(
      map(actions => actions.length)
    ).subscribe(count => {
      this.nbDemandesATraiterSubject.next(count);
    });
  }

  getNbDemandesATraiter(): Observable<number> {
    return this.nbDemandesATraiterSubject.asObservable();
  }


  // Demande d'inscription d'un client 
    getDemandesInscriptionClientTraiter(): Observable<any[]> {
        return this.afs.collection('valid_inscription', ref => 
        ref.orderBy('dateCreation', 'desc')
        ).valueChanges({ idField: 'id' });
    }


    getDemandesInscriptionClientATraiter(): Observable<any[]> {
        if (!this.userData || !this.userData.concession) {
        console.error('User data or concession not available');
        return new Observable<any[]>(observer => {
            observer.next([]);
            observer.complete();
        });
        }
    
        return this.afs.collection('valid_inscription', ref => 
        ref.where('concession', '==', this.userData.concession)
            .orderBy('dateCreation', 'desc')
        ).valueChanges({ idField: 'id' });
    }  

  // Méthode pour obtenir les informations de l'utilisateur connecté
    setUser(userInfos: any) {
        this.userData = userInfos;
        this.userData$.next(userInfos);
    }

    private loadNbDemandesInscriptionClienATraiter() {
        if (!this.userData || !this.userData.concession) {
            console.error('User data or concession not available');
            return;
        }

        this.afs.collection('valid_inscription', ref =>
            ref.where('statut', '==', 'à traiter')
                .where('concession', '==', this.userData.concession)
        ).snapshotChanges().pipe(
            map(actions => actions.length)
        ).subscribe(count => {
            this.nbDemandesInscriptionClientATraiterSubject.next(count);
        }, error => {
            console.error('Error loading demandes:', error);
        });
    }


    getNbDemandesClientInscriptionATraiter(): Observable<number> {
        return this.nbDemandesInscriptionClientATraiterSubject.asObservable();
    }

    deleteDemandeInscriptionClient(id: string): Promise<void> {
        return this.afs.collection('valid_inscription').doc(id).delete();
    }

    // Validation d'une demande d'inscription d'un client
    async confirmerValidation(demandeId: string) {
        const alert = await this.alertController.create({
          header: 'Confirmation',
          message: 'Êtes-vous sûr de vouloir valider cette inscription?',
          buttons: [
            {
              text: 'Annuler',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Confirmer',
              handler: () => {
                this.validerDemandeInscription(demandeId);
              },
            },
          ],
        });
      
        await alert.present();
      }
      
    async validerDemandeInscription(demandeId: string) {
        try {
          // Récupérer les données de la demande d'inscription
          const demandeDoc = await this.afs.collection('valid_inscription').doc(demandeId).get().toPromise();
          const demandeData = demandeDoc.data() as DemandeInscription;
    
          if (!demandeData) {
            throw new Error('Demande non trouvée');
          }
    
          // Créer un utilisateur dans Firebase Authentication
          const userCredential = await this.angularFireAuth.createUserWithEmailAndPassword(demandeData.mail, 'motDePasseTemporaire');
          const user = userCredential.user;
    
          if (!user) {
            throw new Error('Échec de la création de l\'utilisateur');
          }
    
          // Créer un document dans la collection clients
          const clientId = user.uid;
          const nouveauClient = {
            ...demandeData,
            id: clientId,
            dateCreation: firebase.default.firestore.FieldValue.serverTimestamp()
          };
          await this.afs.collection('clients').doc(clientId).set(nouveauClient);
    
          // Mettre à jour la collection concessions avec le nouvel ID de client
          const concessionId = this.userData.concession;
          await this.afs.collection('concessions').doc(concessionId).update({
            clients: firebase.default.firestore.FieldValue.arrayUnion(clientId)
          });
    
          // Envoyer un email de notification au client
          await this.angularFireAuth.sendPasswordResetEmail(demandeData.mail);
          
          // Suppression de la demande d'inscription validée
          await this.afs.collection('valid_inscription').doc(demandeId).delete();
    
          this.toast("La demande d'inscription a été validée et le client a été créé.", 2000);
        } catch (error) {
          console.error('Erreur lors de la validation de la demande d\'inscription:', error);
          this.toast("Erreur lors de la validation de la demande d'inscription.", 2000);
        }
      }
  
  
}
