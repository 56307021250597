import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RdvService} from "./services/rdv.service";
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {LoadingController, NavController} from "@ionic/angular";
import {UserService} from "./services/user.service";
import * as firebase from "firebase/compat/app";
import {ConseilsService} from "./services/conseils.service";
import {ModelesService} from "./services/modeles.service";
import { User } from 'firebase/auth';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

    // Déclarations de variables et initialisations
    db = firebase.default.firestore();
    GooglePlaces: google.maps.places.PlacesService;
    loadingControllerOpen = false;
    private subscription: Subscription;

    // Définition des pages de l'application avec leurs titres, URL et icônes
    public appPages = {
        tdb: {title: 'Tableau de bord', url: '/tableau-de-bord', icon: 'home'},
        rdvAtelier: {title: 'RDV atelier', url: '/rdv-atelier', icon: 'rdv atelier'},
        rdvEssais: {title: 'RDV essais', url: '/rdv-essais', icon: 'rdv essais'},
        concessions: {title: 'Concessions', url: '/concessions', icon: 'people'},
        concession: {title: 'Ma concession', url: '/concession', icon: 'gear'},
        clients: {title: 'Clients', url: '/clients', icon: 'people'},
        gammes: {title: 'Marques', url: '/gammes', icon: 'motorcycle'},
        modeles: {title: 'Modèles', url: '/modeles', icon: 'motorcycle'},
        publications: {title: 'Publications', url: '/publications', icon: 'document'},
        avantages: {title: 'Avantages', url: '/avantages', icon: 'gift'},
        notifications: {title: 'Notifications', url: '/notifications', icon: 'notifications'},
        conseils: {title: 'Conseils', url: '/conseils', icon: 'thumbs-up'},
        gestion: {title: 'Gestion des concessions', url: '/gestion', icon: 'receipt'},
        admins: {title: 'Administrateurs', url: '/administrateurs', icon: 'anonymous'},
        importationDonnees: {title: 'Importation des données', url: '/importation-donnees', icon: 'import'},
        demandesInscriptionClients: {title: 'Demandes d\'inscription client', url: '/demandes-inscription-clients', icon: 'add-user'},
        DemandesInscriptionConcession: {title: 'Demandes d\'invitation d\'une concession', url: '/demandes-inscription-concession', icon: 'concession'},
        
    }

    // Définition des pages accessibles en fonction du type d'utilisateur
    pagesByUserType = {
        admin: [this.appPages.tdb, this.appPages.concessions, this.appPages.gammes, this.appPages.publications, this.appPages.notifications, this.appPages.avantages, this.appPages.conseils, this.appPages.importationDonnees,this.appPages.DemandesInscriptionConcession],
        concession: [this.appPages.tdb, this.appPages.rdvAtelier, this.appPages.rdvEssais, this.appPages.concession, this.appPages.clients, this.appPages.demandesInscriptionClients, this.appPages.modeles, this.appPages.publications, this.appPages.avantages, this.appPages.notifications, this.appPages.conseils],
        marque: [this.appPages.tdb, this.appPages.modeles, this.appPages.publications],
    }

    nbDemandesATraiter: number = 0;
    nbDemandesInscriptionClientATraiter: number = 0;

    // Constructeur de la classe AppComponent
    constructor(
        public rdvService: RdvService,
        private router: Router,
        public angularFireAuth: AngularFireAuth,
        private loadingController: LoadingController,
        public userService: UserService,
        private conseilsService: ConseilsService,
        public modelesService: ModelesService,
        private cdr: ChangeDetectorRef,
    ) {
        // const browserMap = new google.maps.Map(document.createElement('map_canvas'), null);
        // this.GooglePlaces = new google.maps.places.PlacesService(browserMap);
        // const request = {
        //     placeId: "ChIJFUH4en-ZlEcRuaEbEL8novs",
        //     fields: ['address_components', 'geometry', 'url', 'formatted_address']
        // };
        // this.GooglePlaces.getDetails(request, (results, status) => {
        //     if (results) {
        //         this.db.collection('concessions').doc('cvcjofOiyqPrqjsMvDTj').update({
        //             adresse: {
        //                 address_components: results.address_components,
        //                 location: new firebase.default.firestore.GeoPoint(results.geometry.location.lat(), results.geometry.location.lng()),
        //                 url: results.url,
        //                 formatted_address: results.formatted_address,
        //                 place_id: "ChIJFUH4en-ZlEcRuaEbEL8novs"
        //             },
        //         })
        //     }
        // });
        // this.db.collection('modeles').get().then(querySnapshot => {
        //     let modeles = [];
        //     querySnapshot.forEach(modele => {
        //         if(modele.data().marque=='bS98a16ZjfqvW7qO3zL9'){
        //             modeles.push(modele.id);
        //         }
        //     })
        //     this.db.collection('marques').doc('bS98a16ZjfqvW7qO3zL9').update({
        //         modeles: modeles
        //     });
        // });

        // Naviguer vers la page de connexion au démarrage de l'application
        this.router.navigate(['login']).then(() => {
            this.presentLoading("Vérification du statut de connexion").then(() => {
                this.login();
            });
        });
    }
    // Méthode asynchrone pour gérer la connexion de l'utilisateur
    // async login() {
    //     await this.angularFireAuth.onAuthStateChanged(user => {
    //         if (user && user.emailVerified) {
    //             this.loadingController.dismiss()
    //                 .then(() => {
    //                     if (!this.userService.userData) {
    //                         this.presentLoading("Connexion en cours...").then(() => this.userService.loginActions(user));
    //                     }
    //                 })
    //         } else {
    //             return this.loadingController.dismiss();
    //         }
    //     });
    // }


    // Méthode asynchrone pour gérer la connexion de l'utilisateur
    async login() {
        const user = await new Promise<User | null>(resolve => this.angularFireAuth.onAuthStateChanged(resolve));
    
        if (this.loadingControllerOpen) {
            await this.loadingController.dismiss();
            this.loadingControllerOpen = false;
        }
    
        if (user && user.emailVerified) {
            if (!this.userService.userData) {
                await this.presentLoading("Connexion en cours...");
                await this.userService.loginActions(user);
            }
        } else {
            this.router.navigate(['login']);
        }
    }
    
    ngOnInit(): void {
        this.subscription = this.rdvService.dataUpdated$.subscribe(() => {
            this.cdr.detectChanges();
        });
        this.rdvService.dataUpdatedSource.next();  
        this.userService.getNbDemandesATraiter().subscribe(count => {
            this.nbDemandesATraiter = count;
          });
          this.userService.getNbDemandesClientInscriptionATraiter().subscribe(count => {
            this.nbDemandesInscriptionClientATraiter = count;
        });
    
    }




    // Autres méthodes pour gérer des fonctionnalités spécifiques
    nbRdvToValidate(type: string) {
        let newDate = new Date();
        return this.rdvService.getRdvList().filter(e => e.type == type && e.statut == "à traiter" && e.dateRdv>=newDate).length;
    }

    async presentLoading(message) {
        const loading = await this.loadingController.create({
            message: message
        });
        await loading.present();
        this.loadingControllerOpen = true;
    }

    onTypeChange(ev) {
        this.db.collection('utilisateurs').doc(this.userService.userInfos.uid).update({
            type: ev.detail.value
        });
        window.location.reload();
    }

    onMarqueChange(ev) {
        this.db.collection('utilisateurs').doc(this.userService.userInfos.uid).update({
            marque: ev.detail.value
        });
    }

    getNbConseilsNonLus() {
        if (this.userService.userData.lastRiddenConseil) {
            let res = this.conseilsService.getConseils().findIndex(e => e.id == this.userService.userData.lastRiddenConseil);
            return this.conseilsService.getConseils().length - (res + 1);
        } else {
            return -1;
        }
    }

    getPages(type: string){
        return (this.userService.isSuperAdmin() && this.userService.userData.type=='admin') ? [...this.pagesByUserType['admin'], ...[this.appPages.admins]] : this.pagesByUserType[type]
    }
}
