import { Component, OnInit } from '@angular/core';
import {RdvDetailsComponent} from "../rdv-details/rdv-details.component";
import {PopoverController} from "@ionic/angular";
import {UserInfosPopoverComponent} from "../user-infos-popover/user-infos-popover.component";

@Component({
  selector: 'app-icone-infos-utilisateur',
  templateUrl: './icone-infos-utilisateur.component.html',
  styleUrls: ['./icone-infos-utilisateur.component.scss'],
})
export class IconeInfosUtilisateurComponent implements OnInit {

  constructor(
      public popoverController: PopoverController,
  ) { }

  ngOnInit() {}

  async presentPopover(ev: any){
    const popover = await this.popoverController.create({
      component: UserInfosPopoverComponent,
      translucent: true,
      event: ev
      //cssClass: 'bigPopover'
    });
    await popover.present();
  }

}
