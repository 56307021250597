import {Injectable} from '@angular/core';
import * as firebase from "firebase/compat/app";
import {Modele} from "../shared/modele";
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModelesService {

    dataUpdatedSource = new Subject<void>();
    dataUpdated$ = this.dataUpdatedSource.asObservable();

    constructor() {
    }

    marques = {};

    modeles = {};

    async getData(type: string) {
        let db = firebase.default.firestore();
        let self = this;
        let promises = [];
        promises.push(db.collection('marques').onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(marque => {
                self.marques[marque.id] = marque.data();
                self.marques[marque.id].id = marque.id;
            });
            if(type=="admin"){
                self.getNbUsersMarques()
            }
            self.dataUpdatedSource.next();
        }));
        if(type!="marque") {
            promises.push(db.collection('modeles').onSnapshot(function (querySnapshot) {
                querySnapshot.forEach(modele => {
                    self.modeles[modele.id] = modele.data();
                    self.modeles[modele.id].id = modele.id;
                    self.modeles[modele.id].dateSortie = self.modeles[modele.id].dateSortie.toDate();
                });
                self.dataUpdatedSource.next();
            }));
        }
        return Promise.all(promises);
    }

    // Fonction  de vérification si un modèle s'est mal enregistré
    // verifyModeles() {
    //     let modelesArray = Object.values(this.modeles) as Modele[];
    //     modelesArray.forEach((modele, index) => {
    //         const marque = this.getMarque(modele.marque);
    //         if (!marque || typeof marque.nom === 'undefined') {
    //             console.log(`Marque du modèle à l'index ${index} est undefined ou n'a pas de propriété 'nom':`, marque);
    //             console.log(`ID du document Firestore pour le modèle à l'index ${index}:`, modele.id);
    //         }
    //     });
    // }

    getModeles() {
        let res = Object.values(this.modeles) as Modele[];
        res = res.sort((a: any, b: any) => {
            if (a.nom < b.nom) {
                return -1;
            }
            if (a.nom > b.nom) {
                return 1;
            }
            return 0;
        });
        res = res.sort((a: any, b: any) => {
            if (this.getMarque(a.marque).nom < this.getMarque(b.marque).nom) {
                return -1;
            }
            if (this.getMarque(a.marque).nom > this.getMarque(b.marque).nom) {
                return 1;
            }
            return 0;
        });
        return res as any[];
    }

    getByMarque(marque) {
        return this.getModeles().filter(e => e.marque == marque);
    }

    getMarque(id) {
        return id=="adminData" ? {nom:null} : this.marques[id];
    }

    getMarques() {
        let res = Object.values(this.marques) as any[];
        return res.sort(function(a, b){
            if(a.nom < b.nom) { return -1; }
            if(a.nom > b.nom) { return 1; }
            return 0;
        });
    }

    getModele(id) {
        return this.modeles[id];
    }

    getNbUsersMarques(){
        let db = firebase.default.firestore();
        db.collection('clients').get().then(snapshot => {
            snapshot.docs.forEach(doc=>{
                this.marques[doc.data().marque]['nbUsers'] ? this.marques[doc.data().marque]['nbUsers']++ : this.marques[doc.data().marque]['nbUsers']=1;
            })
            this.dataUpdatedSource.next();
        })
    }

    getModeleNbConsults(id: string){
        if(this.getModele(id).consultations){
            let nDate = new Date();
            return this.getModele(id).consultations.filter(c => ((nDate.getTime() - c.date.getTime())/ (1000 * 3600 * 24)) <= 30).length;
        }
        else{
            return 0;
        }
    }
}
