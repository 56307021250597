import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, NavParams, PopoverController, ToastController} from "@ionic/angular";
import {Ionic4DatepickerModalComponent} from "@logisticinfotech/ionic4-datepicker";
import {DatePipe} from "@angular/common";
import * as firebase from "firebase/compat/app";

@Component({
    selector: 'app-ajouter-facture',
    templateUrl: './ajouter-facture.component.html',
    styleUrls: ['./ajouter-facture.component.scss'],
})
export class AjouterFactureComponent implements OnInit {

    storageRef = firebase.default.storage().ref();

    userId: string;
    date: Date;
    numero: string;
    km: number;
    doc: any;

    days = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    datePickerObj: any = {
        // inputDate: this.inOneWeek(), // default new Date()
        // fromDate: new Date(), // default null
        toDate: new Date(), // default null
        //showTodayButton: false, // default true
        closeOnSelect: true, // default false
        //disableWeekDays: [4], // default []
        mondayFirst: true, // default false
        setLabel: 'S',  // default 'Set'
        todayLabel: "Aujourd'hui", // default 'Today'
        closeLabel: 'Annuler', // default 'Close'
        titleLabel: 'Sélectionnez une date', // default null
        monthsList: this.months,
        weeksList: ["D", "L", "M", "M", "J", "V", "S"],
        dateFormat: 'DD MMMM YYYY', // default DD MMM YYYY
        clearButton: false, // default true
        momentLocale: 'fr-FR', // Default 'en-US'
        yearInAscending: true, // Default false
        //btnCloseSetInReverse: true, // Default false
        /*btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: '' // Default ''
        },*/
        arrowNextPrev: {
            nextArrowSrc: 'assets/icones/arrow_forward.svg',
            prevArrowSrc: 'assets/icones/arrow_back.svg'
        }, // This object supports only SVG files.
        /*highlightedDates: [
            { date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff' },
            { date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff' }
        ],*/ // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    }

    constructor(
        private popoverController: PopoverController,
        private navParams: NavParams,
        public modalCtrl: ModalController,
        private loadingController: LoadingController,
        private toastCtrl: ToastController,
    ) {
        this.userId = navParams.get('id');
    }

    ngOnInit() {
        interface HTMLInputEvent extends Event {
            target: HTMLInputElement & EventTarget;
        }

        const fileUploader = document.getElementById('myFile');
        fileUploader.addEventListener('change', (event?: HTMLInputEvent) => {
            const files = event.target.files;
            if (files.length > 0) {
                this.doc = files[0];
            }
        });
    }

    close() {
        this.popoverController.dismiss();
    }

    valider() {
        this.presentLoading("Ajout de la facture ...").then(() => {
            let fileRef = this.storageRef.child('factures').child(this.userId+'/'+this.numero);
            fileRef.put(this.doc).then(snapshot => {
                fileRef.getDownloadURL().then((lienFile) => {
                    let newFacture = {
                        date: this.date,
                        numero: this.numero,
                        km: this.km,
                        link: lienFile
                    }
                    let db = firebase.default.firestore();
                    db.collection('clients').doc(this.userId).update({
                        factures: firebase.default.firestore.FieldValue.arrayUnion(newFacture)
                    }).then(() => this.loadingController.dismiss())
                        .then(() => this.popoverController.dismiss(newFacture))
                        .then(() => this.toast("La facture a bien été ajoutée.", 2000))
                });
            });
        });

    }

    async openDatePicker(debut: boolean) {
        const datePickerModal = await this.modalCtrl.create({
            component: Ionic4DatepickerModalComponent,
            componentProps: {
                'objConfig': this.datePickerObj,
                'selectedDate': this.date ? this.date : new Date()
            },
            cssClass: 'li-ionic4-datePicker'
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data && data.data.date && data.data.date != "Invalid date") {
                    let newDate = new Date();
                    let dateData = data.data.date.split(" ");
                    newDate.setDate(dateData[0]);
                    newDate.setMonth(this.months.findIndex(e => e.toLowerCase() == dateData[1].toLowerCase()));
                    newDate.setFullYear(dateData[2]);
                    this.date = newDate;
                }
            });
    }

    dateTransform(d: any) {
        return new DatePipe('fr-FR').transform(d, 'd MMMM YYYY');
    }

    async toast(message: string, duration: number) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
    }

    async presentLoading(message: string) {
        return this.loadingController.create({
            message: message
        });
    }
}
