import {Injectable} from '@angular/core';
import * as firebase from "firebase/compat/app";
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConseilsService {

    db = firebase.default.firestore();

    dataUpdatedSource = new Subject<void>();
    dataUpdated$ = this.dataUpdatedSource.asObservable();
    conseils = {};/*[
        {
            titre: "La création de vidéos sans effort !",
            texte: "La création de vidéos demande une maîtrise d'un grand nombre d'outils. À moins d'être vidéaste ou de passer par des professionnels, créer une vidéo peut prendre... beaucoup (trop) de temps. Des outils intuitifs existent, comme WOXO.\n" +
                "WOXO est une plateforme qui permet de créer sans effort des vidéos à partir de feuilles de calcul et d'afficher du contenu social avec des widgets pour améliorer votre marketing. Un outil pratique au quotidien, pour les créateurs de contenu et les équipes marketing !\n" +
                "Visitez https://woxo.tech"
        },
        {
            titre: "Comment fidéliser vos clients ?",
            texte: "Les consommateurs sont connus pour être versatiles. Ils peuvent très rapidement passer d’une marque ou entreprise à une autre. Néanmoins, certains facteurs favorisent la fidélisation des clients.\n" +
                "86 % des consommateurs estiment que la fidélisation n’est possible qu’à partir du moment où une forme de convivialité existe entre la marque et les consommateurs.\n" +
                "Il existe de nombreuses manières de vous y prendre pour fidéliser vos clients : valeurs de votre entreprise, satisfaction client, service client exemplaire, programme de fidélité, offres exclusives…\n" +
                "Vous l’avez compris, les clients fidèles achètent plus, plus régulièrement, et le coût de la vente à ces clients est plus faible. Un client satisfait est précieux, car il reviendra chez vous, mais aussi parce qu’il vous recommandera à ses proches par la magie du bouche à oreille. Nous vous détaillerons toutes ces actions au fil de l’année."
        },
        {
            titre: "Optimiser sa fiche Google my business",
            texte: "Il est important de savoir comment optimiser sa fiche Google my business, cet outil de Google est très important pour la visibilité de votre business. Il permet de créer des fiches établissement dans le but de booster votre référencement local…\n" +
                "Voici les 5 points principaux à respecter :\n" +
                "•    Obtenez des avis google pour votre seo et votre réputation\n" +
                "•    Bien remplir les informations sur sa fiche (nom, adresse, horaires, site web)\n" +
                "•    Mettre des photos optimisées pour votre audience\n" +
                "•    Optimiser son site internet pour le référencement local\n" +
                "•    Soyez régulier avec les google posts (publier minimum 1 fois par mois)."
        }
    ]*/

    constructor() {
    }

    async getData(){
        let self = this;
        return this.db.collection('conseils').onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(conseil => {
                if(conseil.data().date) conseil.data().date = conseil.data().date.toDate();
                self.conseils[conseil.id] = conseil.data();
            });
            self.dataUpdatedSource.next();
        });
    }

    getConseils(){
        return Object.values(this.conseils) as any[];
    }
}
