import {Component, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {MembresService} from "../../services/membres.service";

@Component({
    selector: 'app-choose-rdv-membre',
    templateUrl: './choose-rdv-membre.component.html',
    styleUrls: ['./choose-rdv-membre.component.scss'],
})
export class ChooseRdvMembreComponent implements OnInit {

    choosenMember: any = null;

    constructor(
        public popoverController: PopoverController,
        public membresService: MembresService
    ) {
    }

    ngOnInit() {
    }

    fermer() {
        this.popoverController.dismiss({
            membre: this.choosenMember
        });
    }

}
