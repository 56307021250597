import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {interval} from "rxjs";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

    @Input() title: string;

    constructor(
        private router: Router,
        public userService: UserService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.userService.entityUpdate.subscribe(() => {
            this.cd.detectChanges();
        })
        // const source = interval(1000);
        // source.subscribe(val => {
        //   this.cd.detectChanges();
        // });
    }

    goMessagerie() {
        this.router.navigate(['messagerie']);
    }

    nbMessages() {
        return this.userService.entityData?.messagerie?.aTraiter.length;
    }

}
