import {Injectable} from '@angular/core';
import {AlertController, PopoverController, ToastController} from "@ionic/angular";
import {ChooseRdvMembreComponent} from "../components/choose-rdv-membre/choose-rdv-membre.component";
import * as firebase from "firebase/compat/app";
import { Subject } from 'rxjs';
// import {UserService} from "./user.service";

@Injectable({
    providedIn: 'root'
})
export class RdvService {

    dataUpdatedSource = new Subject<void>();
    dataUpdated$ = this.dataUpdatedSource.asObservable();

    data: any = {};/*[
        {
            client: "rSqnWOv4ZwacbdfiprS3",
            dateEnvoi: new Date(),
            dateRdv: new Date(),
            membre: "Julien",
            model: {
                nom: "Yamaha R1M",
                categorie: "Moto",
                cylindree: "998",
                enStock: true
            },
            motif: "Pose d’accessoires",
            type: "atelier",
            statut: "validé",
        },
        {
            client: "rSqnWOv4ZwacbdfiprS3",
            dateEnvoi: new Date(),
            dateRdv: new Date(),
            membre: "Lucas",
            model: {
                nom: "Yamaha R1M",
                categorie: "Moto",
                cylindree: "998",
                enStock: true
            },
            type: "essai",
            statut: "à traiter",
        },
        {
            client: "rSqnWOv4ZwacbdfiprS3",
            dateEnvoi: new Date(),
            dateRdv: new Date(),
            membre: "Baptiste",
            model: {
                nom: "Yamaha R1M",
                categorie: "Moto",
                cylindree: "998",
                enStock: true
            },
            type: "essai",
            statut: "validé",
        },
        {
            client: "rSqnWOv4ZwacbdfiprS3",
            dateEnvoi: new Date(),
            dateRdv: new Date(),
            membre: "Valentin",
            model: {
                nom: "Yamaha R1M",
                categorie: "Moto",
                cylindree: "998",
                enStock: true
            },
            type: "essai",
            statut: "refusé",
        },
        {
            client: "rSqnWOv4ZwacbdfiprS3",
            dateEnvoi: new Date(),
            dateRdv: new Date(),
            membre: null,
            model: {
                nom: "Yamaha R1M",
                categorie: "Moto",
                cylindree: "998",
                enStock: true
            },
            motif: "Révision",
            statut: "à traiter",
            type: "atelier",
        },
        {
            client: "rSqnWOv4ZwacbdfiprS3",
            dateEnvoi: new Date(),
            dateRdv: new Date(),
            membre: "Nicolas",
            model: {
                nom: "Yamaha R1M",
                categorie: "Moto",
                cylindree: "998",
                enStock: true
            },
            motif: "Révision",
            statut: "validé",
            type: "atelier",
        }
    ]*/

    statuts = ["à traiter", "validé", "refusé"];

    motifs = ["Pose d’accessoires", "Réparation", "Révision", "Autre"];

    db = firebase.default.firestore();

    constructor(
        private alertController: AlertController,
        public toastCtrl: ToastController,
        public popoverController: PopoverController,
        // public userService: UserService,
    ) {
        // this.fetchRdvs();
    }
// Dans votre service
// fetchRdvs(): Promise<any> {
//     return new Promise((resolve, reject) => {
//         if (this.userService.userData && this.userService.userData.marque) {
//             const marqueId = this.userService.userData.marque;
//             this.db.collection('marque')
//                 .where('id', '==', marqueId)
//                 .where('type', '==', 'essai')
//                 .where('statut', '==', 'valider')
//                 .get()
//                 .then(querySnapshot => {
//                     resolve(querySnapshot.size);
//                 })
//                 .catch(error => {
//                     console.error("Error getting documents: ", error);
//                     reject(error);
//                 });
//         }
//         else {
//             resolve({});
//         }
//     });
// }

    

    getRdvList(){
        return Object.values(this.data) as any[];
    }

    getRdv(id){
        return this.data[id];
    }

    async refuserAlert(rdv) {
        const alert = await this.alertController.create({
            header: "Refuser le rendez-vous",
            message: "Êtes-vous sûr de vouloir refuser le rendez-vous?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Refuser',
                    handler: (blah) => {
                        this.refuser(rdv);
                    }
                }
            ]
        });

        await alert.present();
    }

    refuser(rdv) {
        rdv.statut = "refusé";
        this.update(rdv.id);
        this.toast("Le rendez-vous atelier a été refusé.", 2000);
    }

    async presentValiderPopover(rdv) {
        const popover = await this.popoverController.create({
            component: ChooseRdvMembreComponent,
            componentProps: {
                rdv: rdv,
            },
            translucent: true,
        });
        await popover.present();
        const {data} = await popover.onDidDismiss();
        if (data) {
            if (data.membre != "") {
                rdv.membre = data.membre;
            }
            this.valider(rdv);
        }
    }

    valider(rdv) {
        rdv.statut = "validé";
        this.update(rdv.id);
        this.toast("Le rendez-vous atelier a été validé.", 2000);
    }

    async supprimerAlert(rdv) {
        const alert = await this.alertController.create({
            header: "Supprimer le rendez-vous",
            message: "Êtes-vous sûr de vouloir supprimer définitivement le rendez-vous? Ses informations ne seront plus accessibles.",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.supprimer(rdv);
                        this.popoverController.dismiss();
                    }
                }
            ]
        });

        await alert.present();
    }

    supprimer(rdv) {
        //this.data = this.data.filter(e => e != rdv);
        this.toast("Le rendez-vous a été supprimé.", 2000);
    }


    async toast(message: string, duration: number) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
    }

    sortByDateRdv(arr) {
        return arr.sort((a: any, b: any) => {
            return a.dateRdv.getTime() - b.dateRdv.getTime();
        });
    }

    sortByStatut(arr) {
        return arr.sort((a: any, b: any) => {
            return (a.statut === b.statut)? 0 : a.statut == 'à traiter'? -1 : 1;
        });
    }

    update(id){
        this.db.collection('rdv').doc(id).update(this.getRdv(id));
    }

    getAtelierATraiter(){
        return this.getRdvList().filter(e => e.type=='atelier' && e.statut=='à traiter' && (e.dateRdv > new Date())).length;
    }

    getEssai(){
        return this.getRdvList().filter(e => e.type=='essai' && (e.dateRdv > new Date())).length;
    }
}
