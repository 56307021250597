export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBq_84zYVs0wQw0bQuoscazBT0dMjm89r8",
    authDomain: 'concessio-6e799.firebaseapp.com',
    databaseURL: 'https://concessio-6e799-default-rtdb.europe-west1.firebasedatabase.app/',
    projectId: 'concessio-6e799',
    storageBucket: 'concessio-6e799.appspot.com',
    messagingSenderId: '110968403624'
  }
};