import {Component, OnInit, ViewChild} from '@angular/core';
import {PopoverController, ToastController} from "@ionic/angular";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'app-create-categorie',
    templateUrl: './create-categorie.component.html',
    styleUrls: ['./create-categorie.component.scss'],
})
export class CreateCategorieComponent implements OnInit {

    @ViewChild('inputId', {static: false}) ionInput: { setFocus: () => void; };

    val: string = '';

    constructor(
        private popoverController: PopoverController,
        public toastCtrl: ToastController,
        public userService: UserService
    ) {
    }

    ngOnInit() {
    }

    ionViewDidEnter(){
        setTimeout(() => this.ionInput.setFocus(), 100)
    }

    add() {
        this.userService.entityData.categories ? this.userService.entityData.categories.push(this.val) : this.userService.entityData.categories = [this.val];
        this.userService.updateEntityData().then(()=>{
            this.close(this.val);
            this.toast("La catégorie a été ajoutée.", 2000);
        });
    }

    close(val: any) {
        this.popoverController.dismiss(val);
    }

    async toast(message: string, duration: number) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
    }
}
