import {Component, Input, OnInit} from '@angular/core';
import {Ionic4DatepickerModalComponent} from "@logisticinfotech/ionic4-datepicker";
import {ModalController} from "@ionic/angular";
import {DatePipe} from "@angular/common";
import {RdvService} from "../../services/rdv.service";

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {

    @Input() date: any;
    @Input() type: any;
    @Input() id: any;

    months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    datePickerObj: any = {
        //inputDate: new Date('2018-08-10'), // default new Date()
        //fromDate: new Date('2016-12-08'), // default null
        //toDate: new Date('2018-12-28'), // default null
        //showTodayButton: false, // default true
        closeOnSelect: true, // default false
        //disableWeekDays: [4], // default []
        mondayFirst: true, // default false
        setLabel: 'S',  // default 'Set'
        todayLabel: "Aujourd'hui", // default 'Today'
        closeLabel: 'Annuler', // default 'Close'
        titleLabel: 'Sélectionnez une date', // default null
        monthsList: this.months,
        weeksList: ["S", "L", "M", "M", "J", "V", "S"],
        dateFormat: 'DD MMMM YYYY', // default DD MMM YYYY
        clearButton: false, // default true
        momentLocale: 'fr-FR', // Default 'en-US'
        yearInAscending: true, // Default false
        //btnCloseSetInReverse: true, // Default false
        /*btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: '' // Default ''
        },*/
        arrowNextPrev: {
            nextArrowSrc: 'assets/icones/arrow_forward.svg',
            prevArrowSrc: 'assets/icones/arrow_back.svg'
        }, // This object supports only SVG files.
        /*highlightedDates: [
            { date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff' },
            { date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff' }
        ],*/ // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    }

    constructor(
        public modalCtrl: ModalController,
        public rdvService: RdvService
    ) {
    }

    ngOnInit() {

    }

    async openDatePicker() {
        const datePickerModal = await this.modalCtrl.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerObj,
                'selectedDate': this.date
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data && data.data.date && data.data.date != "Invalid date") {
                    let dateData = data.data.date.split(" ");
                    this.date.setDate(dateData[0]);
                    this.date.setMonth(this.months.findIndex(e => e.toLowerCase() == dateData[1].toLowerCase()));
                    this.date.setFullYear(dateData[2]);
                }
            });
    }

    changeDate(val) {
        this.date.setDate(this.date.getDate() + val);
        if(this.type=='dateRdv'){
            this.rdvService.getRdv(this.id).dateRdv = this.date;
        }
    }

    dateTransform(d) {
        if(d instanceof Date){
            return new DatePipe('fr-FR').transform(d, 'dd MMMM yyyy');
        }
        else{
            if(d.seconds){
                return new DatePipe('fr-FR').transform(new Date(d.seconds*1000), 'dd MMMM yyyy');
            }
            else{
                return new DatePipe('fr-FR').transform(new Date(d), 'dd MMMM yyyy');
            }
        }
    }

}
