import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {RdvAtelierPageRoutingModule} from './rdv-atelier-routing.module';

import {RdvAtelierPage} from './rdv-atelier.page';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RdvAtelierPageRoutingModule,
        SharedModule,
    ],
    exports: [
    ],
    declarations: [RdvAtelierPage,
    ]
})
export class RdvAtelierPageModule {
}
