import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-user-infos-popover',
  templateUrl: './user-infos-popover.component.html',
  styleUrls: ['./user-infos-popover.component.scss'],
})
export class UserInfosPopoverComponent implements OnInit {

  constructor(
      public userService: UserService,
      private router: Router,
      private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  goMesInfos(){
    return this.router.navigate(['mes-informations']).then(()=>{
      this.popoverController.dismiss();
    })
  }

  disconnect(){
    return this.userService.signOut().then(()=>{
      this.popoverController.dismiss();
    })
  }
}
