import { Component, OnInit } from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-bientot-popover',
  templateUrl: './bientot-popover.component.html',
  styleUrls: ['./bientot-popover.component.scss'],
})
export class BientotPopoverComponent implements OnInit {

  constructor(
      private popoverController: PopoverController,
  ) { }

  ngOnInit() {}

  close() {
    this.popoverController.dismiss();
  }

}
