import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss'],
})
export class TelInputComponent implements OnInit {

  @Input() tel: any;
  @Input() required: boolean = false;
  @Output() telChange = new EventEmitter<string>();

  highlight = {'box-shadow': '1px 1px 3px rgb(211 0 0 / 35%)'};
  
  constructor() { }

  ngOnInit() {}

  blockNonNumbers(event: KeyboardEvent) {
    // Liste des touches autorisées
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', '+'];

    // Si la touche est un chiffre ou fait partie des touches autorisées, on ne fait rien
    if (isNaN(Number(event.key)) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  onInputChange(event: any) {
    this.telChange.emit(event.detail.value);
  }

}
