import {Injectable} from '@angular/core';
import * as firebase from "firebase/compat/app";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    db = firebase.default.firestore();

    concessions : BehaviorSubject<any>  = new BehaviorSubject<any>({});

    constructor() {
    }

    getConcessions() {
        return Object.values(this.concessions.getValue()) as any[];
    }
}
 