import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {NavigationExtras, Router} from "@angular/router";
import {AlertController, ToastController} from "@ionic/angular";
import {UserService} from "../../services/user.service";
import * as firebase from "firebase/compat/app";
import {Concession} from "../../shared/concession";
import {AdminService} from "../../services/admin.service";
import {ModelesService} from "../../services/modeles.service";

@Component({
    selector: 'app-publication',
    templateUrl: './publication.component.html',
    styleUrls: ['./publication.component.scss'],
})
export class PublicationComponent implements OnInit {

    db = firebase.default.firestore();

    @Input() pub: any;
    @Input() toValidate: boolean = false;

    clicked: boolean = false;

    constructor(
        private router: Router,
        public toastCtrl: ToastController,
        private alertController: AlertController,
        private userService: UserService,
        private adminService: AdminService,
        private modelesService: ModelesService,
    ) {
    }

    ngOnInit() {
    }

    dateTransform(d: any) {
        return new DatePipe('fr-FR').transform(d, 'dd/MM/yy');
    }

    onCLick() {
        if (this.isAvantage()) {
            this.openAvantageDetails();
        }
        else{
            this.clicked = !this.clicked;
        }
    }

    openAvantageDetails() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                avantage: JSON.stringify(this.pub)
            }
        };
        this.router.navigate(['ajouter-avantage'], navigationExtras);
    }

    isAvantage() {
        return this.pub.type;
    }

    editPublication() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                pub: JSON.stringify(this.pub)
            }
        };
        this.router.navigate(['ajouter-publication'], navigationExtras);
    }

    async alertDeletePub() {
        const alert = await this.alertController.create({
            header: "Supprimer la publication",
            message: "Êtes-vous sûr de vouloir supprimer la publication?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Supprimer',
                    handler: (blah) => {
                        this.toValidate ? this.unvalidatePub() : this.deletePub();
                    }
                }
            ]
        });

        await alert.present();
    }

    deletePub() {
        delete this.userService.publications[this.pub.id];
        this.userService.entityData.publications = this.userService.entityData.publications.filter(e => e != this.pub.id);
        Promise.all([
            this.userService.updateEntityData(),
            this.db.collection('publications').doc(this.pub.id).delete(),
            firebase.default.storage().ref().child('publications').child(this.pub.id + '.jpg').delete()
        ]).then(() => {
            this.toast("La publication a été supprimée.", 2000);
        });
    }

    async toast(message: string, duration: number) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration
        });
        toast.present();
    }

    getAuteur() {
        if (this.pub.author) {
            switch (this.pub.author.type) {
                case "concession":
                    return this.userService.userData.type=='admin' ? this.adminService.concessions.getValue()[this.pub.author.id] : this.userService.entityData;
                case "marque":
                    return this.modelesService.getMarque(this.pub.author.id);
                default:
                    return {
                        nom: "Concessio",
                        logo: "assets/logo/concessio-favicon-noir.png"
                    }
            }
        } else {
            return {
                nom: "Concessio",
                logo: "assets/logo/concessio-favicon-noir.png"
            }
        }
    }

    async alertAcceptPub() {
        const alert = await this.alertController.create({
            header: "Valider la publication",
            message: "Êtes-vous sûr de vouloir publier cette publication ?",
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                    }
                },
                {
                    text: 'Valider',
                    handler: (blah) => {
                        this.validerPublication();
                    }
                }
            ]
        });

        await alert.present();
    }

    validerPublication() {
        this.userService.presentLoading("Validation de la publication...").then(() => {
            this.pub.validatedConcessions.push(this.userService.idEntity);
            this.userService.publications[this.pub.id] = this.pub;
            delete this.userService.publicationsToValidate[this.pub.id];
            this.db.collection('publications').doc(this.pub.id).update({
                validatedConcessions: this.pub.validatedConcessions
            }).then(() => this.userService.loadingController.dismiss())
                .then(() => this.userService.toast("La publication a été validée.", 2000))
        })
    }

    unvalidatePub() {
        delete this.userService.publicationsToValidate[this.pub.id];
        this.pub.unvalidatedConcessions ? this.pub.unvalidatedConcessions.push(this.userService.idEntity) : this.pub['unvalidatedConcessions'] = [this.userService.idEntity];
        this.db.collection('publications').doc(this.pub.id).update(this.pub).then(() => {
            this.toast("La publication a été supprimée.", 2000);
        });
    }

    isAuteur(){
        return this.pub.author.type == this.userService.userData.type;
    }

}
