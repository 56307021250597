import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RdvService} from "../../services/rdv.service";
import {DatePipe} from "@angular/common";
import {MembresService} from "../../services/membres.service";
import {UserService} from "../../services/user.service";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-rdv-atelier',
    templateUrl: './rdv-atelier.page.html',
    styleUrls: ['./rdv-atelier.page.scss'],
})
export class RdvAtelierPage implements OnInit {

    currentDate = new Date();
    choosenStatut: string;
    chosenResponsable: string;
    recherche: string = "";
    private subscription: Subscription;

    columns = ["client", "date et heure du rdv", "motif", "validation"];

    constructor(
        public rdvService: RdvService,
        public membresService: MembresService,
        public userService: UserService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.subscription = this.rdvService.dataUpdated$.subscribe(() => {
            this.cdr.detectChanges();
        });
        this.rdvService.dataUpdatedSource.next();
    }

    ngOnDestroy() {
        if(this.subscription)
        this.subscription.unsubscribe();
      }



    getData() {        
        let res = this.rdvService.getRdvList().filter(e => e.type == 'atelier');
        if (this.recherche) {
            res = res.filter(e =>
                (this.userService.getClient(e.client).nom.toLowerCase().indexOf(this.recherche.toLowerCase()) > -1) ||
                (this.userService.getClient(e.client).prenom.toLowerCase().indexOf(this.recherche.toLowerCase()) > -1) ||
                (e.model?.nom.toLowerCase().indexOf(this.recherche.toLowerCase()) > -1) ||
                (e.motif?.toLowerCase().indexOf(this.recherche.toLowerCase()) > -1));
        } else {
            res = res.filter(e => this.notPastDay(e.dateRdv));
            // res = res.filter(e => this.goodDay(e.dateRdv));
            res = this.choosenStatut ? res.filter(e => e.statut == this.choosenStatut) : res;
            res = this.chosenResponsable ? res.filter(e => e.membre == this.chosenResponsable) : res;
        }
        res = this.rdvService.sortByDateRdv(res);
        return this.rdvService.sortByStatut(res);
    }

    changeDate(val) {
        this.currentDate.setDate(this.currentDate.getDate() + val);
    }

    dateTransform(d: any) {
        return new DatePipe('fr-FR').transform(d, 'dd MMMM yyyy');
    }

    goodDay(date: Date) {
        return date.getFullYear() == this.currentDate.getFullYear()
            && date.getMonth() == this.currentDate.getMonth()
            && date.getDate() == this.currentDate.getDate();
    }

    notPastDay(date: Date) {
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        return date >= now;
    }

    changedSearch() {
        this.currentDate = new Date();
        this.choosenStatut = null;
    }

    /*changeSearch($event) {

        this.listUsersFiltree = [];
        this.listUsersFiltreeToShow = [];
        this.orderListUsers();

        //Si rien dans champ recherche
        if (!$event.target.value) {
            this.addPartners();
            return;
        }

        //Si contenu dans champ recherche

        else {

            //ajouter tous les utilisateurs de la base qui correspondent à la recherche
            this.listUsersFiltree = this.listUsers.filter(currentPartner => {
                return currentPartner.nom && $event.target.value && (currentPartner.nom.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1 || (currentPartner?.preferencesRecherche?.ville?.nom)&&(currentPartner.preferencesRecherche.ville.nom.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1));
            });
            this.addPartners();
        }
        this.cd.detectChanges();
    }*/

}
